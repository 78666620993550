<template>
    <div class="document-search">
        <div class="search-bar">
            <div class="search-input">
                <q-icon color="#C4C4C4" type="search"></q-icon>
                <input
                    v-model="search"
                    @input="handleInput"
                    type="text"
                    placeholder="Doorzoek documenten"
                    autofocus
                    ref="searchInput"
                />
                <span class="results">
                    <span v-if="search && !loadingSuggestions">{{ suggestions.length }} {{ suggestions.length === 1 ? 'Resultaat' : 'Resultaten' }}</span>
                </span>

            </div>
        </div>
        <transition-group name="suggestions-list" class="documents-container">
            <div v-for="(suggestion, index) in suggestions" :key="suggestion.id" class="q-card-small document-card" :style="`--length: ${suggestions.length - 1};--index: ${index}`">
                <p class="rich-text" v-if="suggestion.type === 'pdf'" v-html="suggestion.text"></p>
                <div v-else-if="suggestion.type === 'rsx'">
                    <rsx-table :specificationPosts="[{
                        specificationsPostNumber: suggestion.specificationsPostNumber,
                        description: suggestion.description,
                        unit: suggestion.unit,
                        value: suggestion.value
                    }]"></rsx-table>
                </div>
                <div class="bottom flex align-center space-between mt-xs">
                    <p class="document-name weight-500">{{ suggestion.documentName }}</p>
                    <q-button class="open-button" size="xsmall" variation="ghost" @click="openSource(suggestion)">Open bron</q-button>
                </div>
            </div>
        </transition-group>
    </div>
</template>

<script>
import _ from 'lodash';

import { SEARCH_PROJECT_DOCUMENTS } from '@/graphql/queries.js';
import RsxTable from '../rsxmetadata/RsxTable.vue';

export default {
    name: 'document-search',
    components: { RsxTable },
    props: {
        documentFilterId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            search: '',
            loadingSuggestions: false,
            suggestions: []
        }
    },
    methods: {
        openSource(suggestion) {
            if (suggestion.type === 'pdf')
                this.documentViewerController.OpenDocumentViewer(suggestion.documentId, { searchQuery: this.search })
            else if (suggestion.type === 'rsx')
                this.documentViewerController.OpenDocumentViewer(suggestion.documentId, { specificationsPostNumber: suggestion.specificationsPostNumber })
        },
        handleInput() {
            this.loadingSuggestions = true;
            this._getSuggestions();
        },
        _getSuggestions: _.debounce(function() {
            this.getSuggestions();
        }, 300),
        getSuggestions() {
            if (this.search.length == '') {
                this.suggestions = [];
                this.loadingSuggestions = false;
                return;
            }
            const projectJwtString = localStorage.getItem('projectJwt')
            const projectJwt = JSON.parse(projectJwtString) || {};

            this.$apollo.query({
                query: SEARCH_PROJECT_DOCUMENTS,
                variables: {
                    projectId: this.$route.params.id,
                    search: this.search,
                    documentId: this.documentFilterId,
                    projectJwt: projectJwt.jwt
                },
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                this.suggestions = result.data.documents_searchProjectDocuments
                    .map((suggestion, index) => ({
                        id: `${suggestion.document_id}-${index}`,
                        type: suggestion.type,
                        documentId: suggestion.document_id,
                        documentName: suggestion.document_name,
                        text: suggestion.text ? this.markSearch(suggestion.text) : '',
                        specificationsPostNumber: suggestion.specifications_post_number ? this.markSearch(suggestion.specifications_post_number) : '',
                        mainCode: suggestion.main_code,
                        description: this.markSearch((suggestion.main_code_fixed_text || '') + '<br/>' + (suggestion.main_code_free_text || '')),
                        value: suggestion.value,
                        unit: suggestion.unit
                    }))

                this.loadingSuggestions = false;
            })
        },
        markSearch(text) {
            const replacePrefix = '<span style="background-color: #F4B976;border-radius: 3px; padding: 1px 2px; margin: -1px 0;">';
            const replaceSuffix = '</span>';

            return text.replaceAll('<em>', replacePrefix).replaceAll('</em>', replaceSuffix)
        }
    },
    watch: {
        documentFilterId: function() {
            this.getSuggestions();
        }
    },
    mounted() {
        this.$refs.searchInput.focus();
        this.$el.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                this.suggestions = [];
                this.search = '';
                this.$emit('stop-search')
            }
        });
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.document-search {
    display: flex;
    flex-direction: column;

    .search-bar {
        width: 100%;
        z-index: 1;

        .search-input {
            display: flex;
            align-items: center;
            gap: 8px;


            background-color: white;

            padding: 4px 18px;

            input {
                flex-grow: 1;
                border: none;
                outline: none;

                &::placeholder {
                    color: $color-grey-5;
                }
            }

            .results {
                color: $color-grey-5;
                font-size: 14px;
                pointer-events: none;
                user-select: none;
                width: 100px;
            }
        }

    }
    .documents-container {
        position: absolute;
        top: 100%;
        right: 0;
        height: 500px;

        display: flex;
        flex-direction: column;
        gap: 16px;


        overflow-y: auto;

        z-index: 10;
        max-height: calc(100vh - 200px);

        .document-card {
            padding: 18px;
            width: 100%;

            .rich-text {
                white-space: pre-wrap;
            }

            .bottom {
                width: 100%;
            }
        }
    }
}

.suggestions-list-move, .suggestions-list-enter-active, .suggestions-list-leave-active {
    transition: .2s ease;
    -webkit-transition: .2s ease;
}
.suggestions-list-enter {
    opacity: 0;
    scale: 0.6;
    translate: 0 -50px;
}
.suggestions-list-enter-active {
    transition-delay: calc(var(--index) * 20ms);
    -webkit-transition-delay: calc(var(--index) * 20ms);
}
.suggestions-list-leave-to {
    translate: 0 -50px;
    opacity: 0;
}

.open-button {
    width: 100px;
}

</style>