<template>
    <div :class="['row center-xs access-base', dontCenterContent.includes(currentRoute) ? '' : 'middle-xs']">
        <nav v-if="!hideNavbar.includes(currentRoute)" class="navbar" :style="navBorder">
            <q-section class="space-between navbar-margin" paddingLeft="34">
                <div class="back">
                    <div class="qfact-logo-nav" v-if="showBorder.includes(currentRoute)">
                        <img @click="$router.push('/access')" src="/statics/img/Qfact-logo.svg" alt="" />
                    </div>
                </div>
                <q-profile-info
                    :firstName="user.firstName"
                    :lastName="user.lastName"
                    :role="user.email"
                    :image="user.avatar"
                    :notifications="notificationAmount"
                    size="medium"
                    variation="left"
                    dropdown
                >
                    <q-profile-dropdown
                        :firstName="user.firstName"
                        :lastName="user.lastName"
                        :role="user.email"
                        :image="user.avatar"
                        :organisations="user.organisations"
                        @logout="logout"
                        class="dropdown"
                    >
                        <q-section
                        hPadding="40"
                        paddingTop="16"
                        paddingBottom="16"
                        borderBottom>
                            <q-profile-info
                                size="large"
                                variation="top"
                                :firstName="user.firstName"
                                :lastName="user.lastName"
                                :role="user.email"
                                :image="user.avatar"
                                class="dropdown-info"
                            >
                            </q-profile-info>

                            <div class="settings">
                                <q-button to="/access/profile" variation="ghost" size="small">Profiel beheren</q-button>
                                <span v-if="notificationAmount > 0" class="notifications-span">{{ notificationAmount }}</span>
                            </div>
                        </q-section>
                        
                        <user-organisations :organisations='organisations' xPadding="16"></user-organisations>

                    </q-profile-dropdown>
                </q-profile-info>
            </q-section>
        </nav>
        <slot />
        <router-view
            :class="dontCenterContent.includes(currentRoute) ? 'header-margin' : ''"
            @updateNavbar="updateNavbar"
            :key="$route.path"
        />
        <div class="qfact-logo-access" v-if="!showBorder.includes(currentRoute)">
            <img src="/statics/img/Qfact-logo.svg" alt="" />
        </div>
        <notifications />
    </div>
</template>

<script>
import Notifications from '@/components/Notifications';
import UserOrganisations from '@/components/UserOrganisations';
import { ORG_MINE, GET_USER } from '@/graphql/queries';

import { getAddress, extractError } from '@/assets/js/utils'

export default {
    name: 'AccessBase',
    components: {
        Notifications,
        UserOrganisations
    },
    data() {
        return {
            hideNavbar: ['access', 'access/register/organisation'],
            showBorder: ['access/profile', 'access/profile/settings', 'access/profile/organisations'],
            showBackButton: ['access/profile', 'access/profile/settings', 'access/profile/organisations'],
            dontCenterContent: ['access/profile', 'access/profile/settings', 'access/profile/organisations', 'access/register/organisation'],
            borderStyle: {
                borderBottom: '1px solid #DEE2E6',
            },
            activeOrganisations: [],
            newOrganisations: [],
            notificationAmount: 0
        };
    },
    computed: {
        currentRoute() {
            return this.$route.path.replace(/^\/|\/$/g, '');
        },
        navBorder() {
            return this.showBorder.includes(this.currentRoute) ? this.borderStyle : {};
        },
        organisations() {
            return [...this.activeOrganisations, ...this.newOrganisations];
        },
        user() {
            return this.$store.getters.getUser;
        }
    },
    methods: {
        updateNavbar(avatar) {
            this.user.avatar = avatar;
        },
        getUser() {
            this.$apollo
                .query({
                    query: GET_USER,
                    variables: { id: this.user.id },
                    fetchPolicy: 'no-cache',
                })
                .then((response) => {
                    this.$store.commit('setUser', response.data.user);
                })
                .catch((err) => {
                    this.$store.commit('resetState');
                    this.$router.push('/login');
                    this.$store.commit('notify', extractError(err))
                });
        },
        logout() {
            this.$store.commit('resetState');
            this.$router.push('/login');
        },
        access(id) {
            console.log('access ' + id);
        },
        getAddress(address) {
            return getAddress(address);
        }
    },
    created() {
        this.getUser();
    },
    apollo: {
        userOrganisations: {
            query: ORG_MINE,
            fetchPolicy: 'no-cache',
            update: (data) => data.org_mine,
            result(res) {
                const vm = this;
                
                if (!res.data.org_mine) return;

                const orgs = res.data.org_mine || [];
                const activeOrgs = []
                const newOrgs = []
                orgs.forEach(org => {
                    const user = org.users.find(user => user.userId === vm.$store.getters.getUser.id)
                    if (org.status == 'active' && user.status === 'active') {
                        activeOrgs.push(org);
                    } else if (user.status === 'requested' || (user.status === 'active' && org.status === 'new')) {
                        newOrgs.push(org);
                    }
                    vm.activeOrganisations = activeOrgs;
                    vm.newOrganisations = newOrgs;                        
                })
            },
        },
    }
};
</script>

<style lang="scss" scoped>
@import '../../components/qds/assets/style/_variables.scss';
@import '../../components/qds/assets/style/fonts/fonts.css';

.organisation-list {
    max-height: 230px;
    overflow: scroll;
    overflow-x: hidden;
}

.qfact-logo-access {
    position: fixed;
    bottom: 48px;
    z-index: -1;
}

.qfact-logo-access img {
    height: 40px;
}

.qfact-logo-nav {
    height: 100%;
    display: flex;
    align-items: center;
}

.qfact-logo-nav img {
    height: 30px;
    cursor: pointer;
}

.settings {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 10px 0;
}

.dropdown-info {
    padding: 10px 0;
}

.back {
    height: 100%;
    display: flex;
    align-items: center;
}

.z-index {
    z-index: 1;
}

.access-base {
    min-height: 100vh;
}

.navbar {
    position: fixed;
    top: 0;
    height: 80px;
    width: 100%;
    background: white;
    
    z-index: 100;
}

.navbar-margin {
    height: 80px;
}

.header-margin {
    margin-top: 80px;
}

.notifications-span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-orange;
    color: #ffffff;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    font-size: 12px;
    font-family: 'Gotham';
    top: -4px;
    transform: translateX(330%);
}
</style>
