<template>
    <div class="q-table" :class="{ 'max-height': hasFilterColumn }" :style="`overflow-x: ${overflowX}; --min-rows: ${loadingRows}`">
        <table 
            id="qtable" 
            :style="`--width: ${width}; --padding-inline: ${paddingInline}`" 
            :class="clickable ? 'clickable' : ''"
        >
            <thead>
                <th
                    v-for="(column, key) in computedColumns"
                    :key="column.field"
                    :style="getColumnStyle(column)"
                    :title="column.title ? column.title : ''"
                >
                    <div
                        class="table-head-wrapper"
                        :style="`
                            ${column.align === 'right' ? 'justify-content:flex-end;' : ''} 
                            ${column.filter ? 'cursor:pointer;' : 'cursor:default;'}
                        `"
                        @click="openFilter(column.field)"
                    >
                        <q-tooltip class="tooltip" :class="{ active: showFilter === column.field && column.filter }" :disabled="!multiLineHeader && !column.tooltipText">
                            <template #tooltip>
                                <p class="small-tooltip">{{ column.tooltipText || column.label }}</p>
                            </template>
                            <span 
                                :class="[multiLineHeader ? 'multi-line' : 'basic']" 
                                class="column-label"
                            >{{ column.label }}</span>
                            <q-icon v-if="column.tooltipText" type="questionCircle"></q-icon>
                        </q-tooltip>

                        <span 
                            v-if="columnsFilters[column.field]"
                            class="applied-filters" 
                            :class="{ show: columnsFilters[column.field] && columnsFilters[column.field].active > 0, active: showFilter == column.field && column.filter }"
                        >
                            <div v-if="columnsFilters[column.field] && !columnsFilters[column.field].label" class="numbers-wrapper" :class="{ 'not-initialized': columnsFilters[column.field].total === 0 }" :style="`--filter-length: ${columnsFilters[column.field].total};  --selected-filters: ${columnsFilters[column.field].active}`">
                                <span v-for="(activeFilter, index) in columnsFilters[column.field].total" :key="index">{{ columnsFilters[column.field].total - index }}</span>
                            </div>
                            <span v-else class="numbers-wrapper">{{ columnsFilters[column.field].label }}</span>
                        </span>

                        <svg
                            v-if="column.filter && column.filterReady"
                            class="filterIcon"
                            :class="{ active: showFilter === column.field && column.filter }"
                            :style="showFilter === column.field && column.filter ? 'transform: rotate(180deg)' : ''"
                            width="12"
                            height="8"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1 1L5.24724 5.23803L9.48988 0.995393"
                                stroke="#ADB5BD"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <q-filter
                            v-if="column.filter"
                            :multiLineHeader="multiLineHeader"
                            :options="column.filterOptions"
                            :field="column.field"
                            :filterName="column.label"
                            :active="showFilter === column.field && column.filter"
                            :variation="column.filterType"
                            :showSearchInput="column.showSearchInput"
                            :disableSorting="column.disableSorting"
                            :isLastColumn="columns.length > 1 && key === lastColumnKey"
                            :defaultSort="column.defaultSort"
                            :sliderMax="column.sliderMax"
                            :loading="column.loading"
                            :latestSort="column.field === latestSortingField"
                            @change="emitFilter($event, column.field)"
                            @close="closeFilter"
                            @checkboxSearchChanged="checkboxSearchChanged($event, column.field)"
                        >
                        </q-filter>
                    </div>
                </th>
            </thead>
            <tbody v-if="!showLoadingState">
                <router-link
                    :class="[noInnerBorder ? 'no-inner-border' : '', { 'distinction-lines': row.collapsable }]"
                    v-for="(row, key) in data"
                    :key="key"
                    :style="row.__customStyling"
                    v-show="row.collapsable === true ? openedGroupKey === row.collapsableGroupKey : true"
                    :to="row.to || ''"
                    custom
                    v-slot="{ href, navigate }"
                >
                <tr tabindex="0" class="show-focus-state" @keydown.enter="navigate">
                    <td
                        v-for="column in computedColumns"
                        :key="column.field"
                        :style="[
                            { 'text-align': column.align || 'left' },
                            { 'overflow-x': !column.tooltip ? 'hidden' : 'visible' },
                        ]"
                        @click="select(row)"
                    >
                        <a 
                            v-if="row.to" 
                            class="router-link" 
                            :class="column.align" 
                            :style="[
                                { 'text-align': column.align || 'left' },
                                { 'overflow-x': !column.tooltip ? 'hidden' : 'visible' }
                            ]"
                            :href="href" 
                            tabindex="-1" 
                            @click="navigate"
                        >
                            <slot name="row" :row="row" :column="column.field">
                                <!-- Default fill table element with columns 'field' value of data object -->
                                <!-- Can be overwritten in template -->
                                <span class="column-field">{{ row[column.field] }}</span>
                            </slot>
                        </a>
                        <slot v-else name="row" :row="row" :column="column.field">
                            <!-- Default fill table element with columns 'field' value of data object -->
                            <!-- Can be overwritten in template -->
                            <span class="column-field">{{ row[column.field] }}</span>
                        </slot>
                    </td>
                </tr>
                </router-link>
                <tr 
                    v-if="data && data.length === 0"
                    :style="'display:flex;justify-content:center;margin-top:8px;width:' + noDataTrWidth"
                >
                    <span class="no-results-text">Geen resultaten gevonden.</span>
                </tr>
            </tbody>

            <tbody v-if="showLoadingState">
                <tr class="loading" v-for="n in loadingRows" :key="n">
                    <td
                        v-for="column in computedColumns"
                        :key="column.field"
                        class="skeleton-loader"
                        :class="column.align"
                        :style="'text-align:' + (column.align ? column.align : 'left')"
                    >
                        <div v-if="column.loadingStyle === 'avatar_text'" class="skeleton-avatar-wrapper">
                            <div class="skeleton-avatar"></div>
                            <div class="skeleton-line" :style="getLoaderColumnWidth(column.loadingWidth)"></div>
                        </div>
                        <div v-else-if="column.loadingStyle === 'avatar'" class="skeleton-avatar"></div>
                        <div
                            v-else-if="column.loadingStyle === 'badge'"
                            class="skeleton-badge"
                            :style="getLoaderColumnWidth(column.loadingWidth)"
                        ></div>
                        <div v-else-if="column.loadingStyle === 'none'"></div>
                        <div v-else class="skeleton-line" :style="getLoaderColumnWidth(column.loadingWidth)"></div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import base64url from 'base64-url';
import { getDate, isValidAnswer, isDefined } from '@/assets/js/utils.js';

/**
 * Table width is default set to 100%. This is changeable to fixid pixels or percentages
 * Each column width can then be set in percentages to divide up the total width of the table.
 */
export default {
    name: 'q-table',
    props: {
        /**
         * properties:
         * 'field' as default displayed field of data object
         * 'label' as label in table head
         * 'width' in percentages as width per column in css values. eg. '20%'
         * 'align' as 'left', 'center' and 'right. Defines alignment of the th and td cell. Default 'left'
         * 'loadingStyle' for skeleton loading animation: default shows a bar. Options: 'avatar', 'avatar_text', 'badge'
         * 'loadingWidth' custom width for the skeleton loading animation. Defaults to a random width between 30% and 80%. Fixed widths in px also supported.
         * 'title' as title of the column header
         * 'tooltip' if true overflow in table row is visible
         */
        columns: {
            type: Array,
            required: true,
        },
        /**
         * Array of objects.
         *
         * They should have the columns 'field' value as attribute or use a v-slot
         */
        data: {
            type: Array,
            required: true,
        },
        /**
         * Width of the table eg. '500px' or '80%'
         */
        width: {
            type: String,
            default: '100%',
        },
        /**
         * If true, the compenents emits an event 'click' with the clicked row as a parameter.
         */
        clickable: {
            type: Boolean,
            default: false,
        },
        /**
         * If true, the compenents shows a number of skeleton rows (loadingRows) as preloader.
         */
        loading: {
            type: Boolean,
            default: false,
        },
        /**
         * Set a debounce in milliseconds to skip the loading state if requests are quick
         */
        loadingDebounce: {
            type: Number,
            default: 100
        },
        /**
         * Number of rows the preloader shows when loading = trruee
         */
        loadingRows: {
            type: Number,
            default: 5,
        },
        /**
         * Boolean for if you want a table with no inner lines
         */
        noInnerBorder: {
            type: Boolean,
            default: false,
        },
        multiLineHeader: {
            type: Boolean, 
            default: false,
        },
        openedGroupKey: {
            type: String,
            default: ''
        },
        // amount of pixels the table should extend horizontally
        paddingInline: {
            type: String,
            default: '8px'
        },
        overflowX: {
            type: String,
            default: 'visible',
            validator: (value) => {
                return value.match(/(visible|hidden|auto)/);
            },
        }
    },

    data() {
        return {
            showFilter: '',
            currentFilter: {},
            noDataTrWidth: '',
            lastColumnKey: this.columns.length - 1,
            waitingForLoadingTimeout: null,
            showLoadingState: this.loading,
            waitNextColumnUpdate: false,
            columnsFilters: {},
            latestSortingField: null
        };
    },
    methods: {
        checkboxSearchChanged(filterData, columnField) {
            this.$emit('filterSearch', {
                column: columnField,
                filterData,
            });
        },
        emitFilter(filter, columnfield) {
            this.currentFilter[columnfield] = { ...filter };

            const filterKeys = Object.keys(this.currentFilter);
            let activeFilters = 0;

            if(filter.sorting) this.latestSortingField = columnfield;

            filterKeys.forEach((field) => {
                const column = this.columns.find(column => column.field === field);
                if(!column) return
                const filterValues = this.currentFilter[field].filter || [];
                if(filterValues.length > 0) activeFilters++;
                this.currentFilter[field].latest = field === this.latestSortingField;
            });

            const path = this.$route.path;
            if(activeFilters > 0) {
                const stringify = JSON.stringify(this.currentFilter);
                const base64 = base64url.encode(stringify);
                this.$router.replace(`${path}?filter=${base64}`).catch(()=>{});;
            } else if(this.$route.query.filter && activeFilters === 0) this.$router.replace(`${path}`).catch(error => {});

            this.$emit('filterUpdated', this.currentFilter);

            this.$nextTick(() => {
                this.updateColumnsFilters();
            })
        },
        closeFilter() {
            this.showFilter = '';
        },
        openFilter(columnName) {
            if (this.showFilter !== columnName) {
                this.showFilter = columnName;
                this.$store.commit('resetBodyListeners');
            }

            let v = this;

            if (this.$store)
                this.$store.commit('addBodyListener', [
                    'filter-panel',
                    () => {
                        v.closeFilter();
                    },
                ]);
        },
        select(row) {
            if(row.to) return
            if (this.clickable) this.$emit('click', row);
        },
        validateContent() {
            const fields = this.columns.map((column) => column.field);
            
            if (!this.data) return;

            this.data.forEach((row) => {
                const rowFields = Object.keys(row);

                fields.forEach((field) => {
                    const hasField = rowFields.includes(field);

                    /**
                     * If object does not have field specified in columns show warning
                     */
                    if (!hasField) console.warn("Object does not have field '" + field + "'", row);
                });
            });
        },
        getColumnStyle(col) {
            return col.width
                ? 'width:' + col.width
                : 'width:' + Math.round(100 / this.columns.length) + '%;min-width:200px';
        },
        getLoaderColumnWidth(width) {
            const percentage = Math.floor(Math.random() * 51) + 30;
            const widthResult = width ? width : `${percentage}%`;
            return `width: ${widthResult}`;
        },
        getRowStyle(row) {
            if (!row.backgroundColor) return '';

            return 'background-color:' + row.backgroundColor;
        },
        getNoDataTrWidth() {
            if (this.data && this.data.length === 0) {
                const table = document.getElementById('qtable');
                this.noDataTrWidth = table ? table.offsetWidth + 'px' : '';
            }
        },
        handleStartLoading() {
            this.waitingForLoadingTimeout = setTimeout(this.startLoadingTimeout, this.loadingDebounce);
        },
        startLoadingTimeout() {
            this.showLoadingState = true;
        },
        handleStopLoading() {
            if(this.waitingForLoadingTimeout) clearTimeout(this.waitingForLoadingTimeout);
            this.showLoadingState = false;
        },
        updateColumnsFilters() {
            this.computedColumns.forEach(column => {
                const filterData = this.currentFilter[column.field] || {};
                let activeFilterOptions = filterData.filter || [];
                let filterOptions = column.filterOptions || [];
                let total = null;
                let label = '';

                if(column.filterType === 'datePicker') {
                    filterOptions = [0, 0];
                    const { from, to } = filterData.filter || {};
                    if(!from && !to) activeFilterOptions = [];
                    else activeFilterOptions = [ from, to ];
                    total = 1;
                } else if(['score','number'].includes(column.filterType)) {
                    filterOptions = [0, 0];
                    const [ from, to ] = activeFilterOptions;
                    if(!from && !to) activeFilterOptions = [];
                    else {
                        activeFilterOptions = [ from, to ];
                        total = 1;
                    }
                }

                if(filterOptions.length === 0 && activeFilterOptions.length === 0) return
                if(total === null) total = Math.max(filterOptions.length, activeFilterOptions.length);
                if(!this.columnsFilters[column.field]) this.columnsFilters[column.field] = {
                    total
                }
                if(total > this.columnsFilters[column.field].total) this.columnsFilters[column.field].total = total;

                if(['datePicker','score','number'].includes(column.filterType)) this.columnsFilters[column.field].active = activeFilterOptions.length > 0 ? 1 : 0;
                else this.columnsFilters[column.field].active = activeFilterOptions.length;

                if(label) this.columnsFilters[column.field].label = label;
            });
        },
        initializeFiltersQuery() {
            const filter = this.$route.query.filter;
            if(filter) {
                const stringify = base64url.decode(filter);
                const object = JSON.parse(stringify);
                this.currentFilter = object;
                Object.keys(object).forEach(key => {
                    const filterSorting = object[key].sorting || [];
                    if(filterSorting.length > 0) this.latestSortingField = key;
                })
            } else this.currentFilter = {};
            
            this.$emit('filterInitialized', this.currentFilter);
        }
    },
    computed: {
        computedColumns: function() {
            let columns = this.columns;
            
            columns = columns.map(column => {
                if(!column.filter) return column

                const filter = this.currentFilter[column.field];
                const filterExists = Boolean(filter && filter.filter);

                let filterOptions = column.filterOptions || [];

                if(column.filterType === 'datePicker') {
                    filterOptions = filterExists ? [
                        filter.filter.from,
                        filter.filter.to
                    ] : [];
                }
                else if(column.filterType === 'number') {
                    const min = filterExists ? filter.filter[0] : column.sliderMin || 0;
                    const max = filterExists ? filter.filter[1] : column.sliderMax || 10;
                    filterOptions = [min, max];
                }
                else {
                    filterOptions = filterOptions
                        .map(filterOption => {
                            filterOption.selected = filterExists ? filter.filter.includes(filterOption.value) : false;
                            return filterOption
                        });
                }

                column.filterOptions = filterOptions;

                column.filterReady = true;
                if(column.filterType === 'number' && Object.keys(column).includes('sliderMax')) {
                    column.filterReady = isDefined(column.sliderMax);
                }

                return column
            })

            const emptyColumnStart = {
                name: '',
                field: 'emptyStart',
                width: this.paddingInline,
                loadingStyle: 'none'
            }
            const emptyColumnEnd = {
                name: '',
                field: 'emptyEnd',
                width: this.paddingInline,
                loadingStyle: 'none'
            }
            if(this.paddingInline && this.paddingInline !== '0px') {
                columns = [ emptyColumnStart, ...columns, emptyColumnEnd ];
                this.lastColumnKey = columns.length - 2;
            }
            else this.lastColumnKey = columns.length - 1;

            return columns
        },
        hasFilterColumn: function() {
            const columns = this.columns || [];
            return columns.some(column => column.filter)
        }
    },
    async created() {
        this.validateContent();
        this.initializeFiltersQuery();
    },
    mounted() {
        if (this.data && this.data.length === 0) {
            const table = document.getElementById('qtable');
            this.noDataTrWidth = table ? table.offsetWidth + 'px' : '';
            window.addEventListener('resize', this.getNoDataTrWidth);
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.getNoDataTrWidth);
    },
    watch: {
        loading: function() {
            if(!this.loadingDebounce) return this.showLoadingState = this.loading;

            if(this.loading) this.handleStartLoading();
            else this.handleStopLoading();
        },
        computedColumns: function() {
            this.updateColumnsFilters();
        },
        '$route.path': function() {
            this.initializeFiltersQuery();
            this.updateColumnsFilters();
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../assets/style/_variables.scss';
@import '../assets/style/fonts/fonts.css';

$base-color: #eef0f1;
$shine-color: #e3e7e9;
$animation-duration: 1.8s;
$avatar-offset: 52 + 16;

.no-results-text {
    padding: 8px 0;
    font-size: 14px;
    color: $color-grey-5;
}

.skeleton-loader {
    @mixin background-gradient {
        background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
        background-size: 600px;
    }

    .skeleton-line {
        float: left;
        height: 14px;
        border-radius: 4px;

        @include background-gradient;
        animation: shine-lines $animation-duration infinite ease-out;

        .skeleton-line ~ .skeleton-line {
            background-color: #ddd;
        }
    }

    .skeleton-badge {
        float: left;
        height: 28px;
        border-radius: 24px;

        @include background-gradient;
        animation: shine-lines $animation-duration infinite ease-out;

        .skeleton-badge ~ .skeleton-badge {
            background-color: #ddd;
        }
    }

    .skeleton-avatar {
        float: left;
        width: 34px;
        height: 34px;
        background-color: $base-color;
        border-radius: 50%;

        @include background-gradient;
        animation: shine-avatar $animation-duration infinite ease-out;
    }
    .skeleton-avatar-wrapper {
        & .skeleton-line {
            margin-top: 10px;
            margin-left: 10px;
        }
    }

    &.left {
        > * {
            float: left;
        }
    }
    &.right {
        > * {
            float: right;
        }
    }
}

@keyframes shine-lines {
    0% {
        background-position: -100px;
    }

    100% {
        background-position: 350px;
    }
}

@keyframes shine-avatar {
    0% {
        background-position: -100px + $avatar-offset;
    }

    100% {
        background-position: 140px + $avatar-offset;
    }
}

.q-table {
    padding-top: 20px;
    margin-top: -20px;

    &.max-height {
        min-height: calc(var(--min-rows) * 63px);
    }

}

table {
    width: var(--width);
    font-family: $font-text;

    border-collapse: collapse;
    table-layout: fixed;

    tbody {
        tr.distinction-lines {
            &:nth-of-type(2n) {
                background:rgba(246, 246, 246, .7);
                // background: rgba(232, 232, 232, .7)
            }
            &:nth-of-type(2n + 1) {
            }
        }
    }
    &.clickable {
        tbody {
            tr {
                cursor: pointer;
            }
        }
    }
}

tr,
thead {
    transition: 200ms;
    &:not(:last-child) {
        border-bottom: 1px solid $color-grey-3;
    }
    &.no-inner-border {
        border-bottom: 0px solid white;
    }
}

tr:not(.loading):hover {
    background-color: #f8f9fa;
    transition: 200ms;
}

.small-tooltip {
    max-width: 400px;
    white-space: wrap;
}

th {
    position: relative;
    user-select: none;
    font-family: Gotham;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0px 0px 10px 0px;
    // white-space: nowrap;
    vertical-align: top;

    .table-head-wrapper {
        display: flex; 
        align-items: center; 
        user-select: none;

        .tooltip {
            display: flex;
            align-items: center;
            gap: 6px;
            
            &.active {
                z-index: 11;
            }
        }

        .column-label {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            
            &.basic {
                -webkit-line-clamp: 1;
            }
            &.multi-line {
                -webkit-line-clamp: 3;
                // max-height: 60px;
            }
        }
    }
}

td {
    font-family: Gotham;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    height: 60px;
    padding: 0;
    text-overflow: ellipsis;

    .router-link {
        display: flex;
        align-items: center;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        margin: 0;
        padding: 0;
        text-decoration: none;
        color: inherit;
        padding: 1px;
        text-overflow: ellipsis;

        &:empty {
            padding: 0;
        }

        &.center {
            justify-content: space-around;
        }
        &.right {
            justify-content: flex-end;
        }
    }
}

.filterIcon {
    margin-top: 2px;
    margin-left: 6px;
    transition: transform .3s ease;
    transform-origin: center 45%;

    &.active {
        z-index: 11;
    }
}

.applied-filters {
    background-color: $color-orange-lighter;
    color: $color-orange;
    border-radius: 6px;
    height: 16px;
    max-width: 0;
    min-width: 0px;
    transform: scale(0);
    transform-origin: center;
    overflow: hidden;
    transition: .25s ease;
    margin-block: auto;

    font-size: 12px;
    text-align: center;

    &.show {
        margin-left: 6px;
        max-width: 30px;
        min-width: 12px;
        transform: scale(1);
        padding-inline: 4px;
    }

    &.active {
        z-index: 11;
    }

    .numbers-wrapper {
        margin-top: calc((var(--selected-filters) - 1) * -16px);
        display: flex;
        flex-direction: column-reverse;
        transition: .3s cubic-bezier(.51,1.6,.45,1.08);
        
        &.not-initialized {
            transition: 5s ease-out !important;
        }
    }
}


</style>
