<template>
    <div class="wrapper">
        <q-table 
            :data="data" 
            :columns="columns" 
            :loading="tableLoading" 
            :loadingRows="computedLoadingTableRows" 
            :clickable="clickable"
            :multiLineHeader="multiLineHeader"
            :groupOptions="groupOptions"
            :openedGroupKey="openedGroupKey"
            :paddingInline="paddingInline"
            :overflowX="overflowX"
            @click="handleRowClick" 
            @filterUpdated="filterUpdated"
            @filterSearch="filterSearchChanged"
            @filterInitialized="handleFilterInitialized"
        >
            <template v-slot:row="{ row, column }">
                <slot name="row" :row="row" :column="column"></slot>
            </template>
        </q-table>
        <div class="paginationContainer">
            <div v-if="!initialized" class="pagination-loader">
                <div class="loader"></div>
            </div>
            <div v-else-if="data.length && pages > 1" class="pagination">
                <button
                    class="pageIndex first show-focus-state" 
                    :class="{ hide: selectedPageList === 1 }"
                    v-html="'<'" 
                    @click="navigateBackwards(1)" 
                    title="Terug"
                    :tabindex="selectedPageList === 1 ? -1 : 0">
                </button>
                <button v-if="pagesList[0] > 1" class="pageIndex show-focus-state" :class="selectedPageList > 3 ? 'firstPageIndex' : ''" @click="navigateToList(1)">1</button>
                <button 
                    v-for="(page, index) in pagesList" 
                    :key="index" 
                    class="pageIndex show-focus-state" 
                    :class="[selectedPageList == page ? 'selected' : '', index == 0 ? '' : 'seperator']"
                    @click="navigateToList(page)">
                    {{ page }}
                </button>
                <button 
                    v-if="pagesList[pagesList.length-1] < pages" 
                    class="pageIndex show-focus-state" 
                    :class="selectedPageList <= pages-3 ? 'lastPageIndex' : ''" 
                    @click="navigateToList(pages)">
                    {{ pages }}
                </button>
                <button 
                    class="pageIndex show-focus-state last" 
                    :class="{ hide: selectedPageList === pages }"
                    v-html="'>'" 
                    @click="navigateForwards(1)" 
                    title="Verder"
                    :tabindex="selectedPageList === pages ? -1 : 0">
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import QTable from './QTable.vue';

export default {
    name: 'q-table-with-pagination',
    props: {
        //  the data used in the table, [Object]
        data: {
            type: Array,
            required: true
        },
        // the attributes to be displayed in the table
        // example [Object]:
        //   label: 'Voornaam'
        //   field: 'firstName'
        columns: {
            type: Array,
            required: true
        },
        // integer of max amount of rows to display in table
        maxRows: {
            type: Number,
            default: 10
        },
        // integer of total amount of items (unpaginated)
        dataLength: {
            type: Number,
            required: true
        },
        // whether a search input is being used, thus manipulating the data
        isSearching: {
            type: Boolean,
            default: false
        },
        // whether to display the table in its loading state
        tableLoading: {
            type: Boolean,
            default: false
        }, 
        multiLineHeader: {
            type: Boolean, 
            default: false
        }, 
        groupOptions: {
            type: Object, 
            required: false,
            default: () => {},
        },
        openedGroupKey: {
            type: String,
            default: ''
        },
        // amount of pixels the table should extend horizontally
        paddingInline: {
            type: String,
            default: '8px'
        },
        overflowX: {
            type: String,
            default: 'visible',
            validator: (value) => {
                return value.match(/(visible|hidden|auto)/);
            },
        },
        loadingTableRows: {
            type: Number,
            default: null
        }, 
        clickable: {
            type: Boolean, 
            default: false
        }
    },
    components: {
        QTable
    },
    data() {
        return {
            selectedPageList: 1,
            initialized: false
        }
    },
    methods: {
        navigateBackwards(step) {
            if(this.selectedPageList <= step) return
            this.navigateToList(this.selectedPageList - step);
        },
        navigateForwards(step) {
            if(this.selectedPageList + step > Math.ceil(this.dataLength / this.maxRows)) return
            this.navigateToList(this.selectedPageList + step);
        },
        navigateToList(list, emit = true) {
            this.selectedPageList = list;
            const path = this.$route.path;
            const existingQueries = this.$route.query;
            this.$router.replace({ path, query: { ...existingQueries, page: list } }).catch(error => {});
            if(emit) this.$emit('pageSelection', this.selectedPageList);
        },
        handleRowClick(row) {
            this.$emit('click', row)
        },
        filterUpdated(currentFilter) {
            this.initialized = false;
            this.navigateToList(1);
            this.$emit('filterUpdated', currentFilter);
        },
        filterSearchChanged(data) {
            this.$emit('filterSearch', data);
        },
        // call this function from parent if the data is being changed by an active filter
        resetCounter() {
            this.navigateToList(1, false);
        },
        updateInitialized() {
            if(!this.initialized && (this.tableLoading || (this.data.length > 0 && this.dataLength === 0))) this.initialized = false;
            else this.initialized = true;
        },
        handleFilterInitialized(filter) {
            this.$emit('filterInitialized', filter);
            const page = parseInt(this.$route.query.page || 1);
            this.selectedPageList = page;
            this.$emit('pageSelection', page);
            if(this.selectedPageList === 1) this.$emit('tableInitialized', filter);
        }
    },
    computed: {
        pages() {
            return Math.ceil(this.dataLength / this.maxRows)
        },
        pagesList() {
            let list = []
            const range = 1;
            for(let i = 1; i <= this.pages; i++) {
                if(i < this.selectedPageList-range && i < this.pages-range*2) continue
                if(i > this.selectedPageList+range && list.length == range*2 + 1) continue
                list.push(i)
            }
            return list
        },
        computedLoadingTableRows: function() {
            if(this.loadingTableRows !== null) return this.loadingTableRows
            return this.maxRows
        }
    },
    created() {
        const page = this.$route.query.page;
        if(page !== undefined) this.selectedPageList = parseInt(page);
    },
    watch: {
        data: function() {
            this.updateInitialized();
        },
        dataLength: function() {
            this.updateInitialized();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../components/qds/assets/style/_variables.scss';

.wrapper {
    // display: flex;
    // flex-direction: column;
    // gap: 10px;

    .paginationContainer {
        position: fixed;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        right: 100px;
        bottom: 1vh;
        user-select: none;

        .pagination {
            display: flex;
            align-self: flex-end;
            cursor: default;
            font-weight: 500;

            .pageIndex {
                all: unset;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                margin-inline: 2px;
                width: 30px;
                height: 20px;
                background-color: white;
                cursor: pointer;
                border-radius: 3px;
                font-family: 'Gotham';
                font-size: 14px;
                user-select: none;

                &.hide {
                    opacity: 0;
                    pointer-events: none;
                }
            }
            .pageIndex:hover {
                opacity: .9;
                background-color: lightgrey;
                transition: opacity .3s ease, background-color .3s ease;
            }
            .selected {
                background-color: #212529 !important;
                opacity: 1;
                color: #FFFFFF;
                border-radius: 3px;
            }

            .first {
                margin-right: 0;
            }
            .last {
                margin-left: 0;
            }

            .firstPageIndex::before {
                content: "...";
                position: absolute;
                right: -10%;
                transform: translateX(40%);
                pointer-events: none;
            }

            .lastPageIndex::before {
                content: "...";
                position: absolute;
                left: -10%;
                transform: translateX(-40%);
                pointer-events: none;
            }
        }

        .pagination-loader {
            width: 100px;
        }
    }
}

.loader {
    $loader-color: $color-grey-5;
    $loader-size: 6px;
    $loader-height: 14px;
    $loader-border-size: 3px;
    $loader-gap: 16px;
    $loader-animation-duration: 1s;
    @import '../../../components/qds/assets/loaders/loaders.scss';
    @include loader12;

    margin-left: 40px;
    margin-bottom: 4px;
}

</style>