<template>
    <div :id="`row-${rowData.id}`" class="label-wrapper" :class="{ disabled, loading }">
        <div class="label-row" @click="toggleShowRow">
            <div class="left">
                <q-icon type="hamburger-menu" class="hamburger-icon" :class="{ show: dragLabels }" color="#ADB5BD"></q-icon>
                <p class="name" v-html="markedName"></p>
                <img v-if="rowData.masterId" class="standard-set" :src="getRowImage(rowData)" alt="">
                <q-tooltip v-if="showTag && rowData.locked" position="bottom">
                    <template #tooltip>
                        <p>Je kunt in dit template geen wijzigingen aanbrengen omdat<br>dit formulier een uniforme beoordelingswijze betreft.</p>
                    </template>
                    <q-tag v-if="showTag && rowData.locked" variation="default" size="small">
                        <div style="display: flex; gap: 6px; align-items: center;">{{ rowData.tagLabel }}<q-icon type="lock-closed" color="grey" width="10px"></q-icon></div>
                    </q-tag>
                </q-tooltip>
                <q-tag v-else-if="showTag" size="small">{{ rowData.tagLabel }}</q-tag>
            </div>
            <div class="right">
                <q-tag v-if="showStatus" size="small" :variation="rowData.status === 'disabled' ? 'danger' : 'success'">{{ rowData.status === 'disabled' ? 'Niet actief' : 'Actief' }}</q-tag>
                <q-options :options="actionOptions" color="#C4C4C4" @input="handleAction" class="action-options"></q-options>
            </div>
        </div>
        <div class="show-loader" :class="collapsed && loadingComponents ? 'show' : 'hide'">
            <div class="center">
                <div class="loader"></div>
            </div>
        </div>
        <div v-if="!disableToggleRow" class="content-container" :class="(collapsed && !loadingComponents) || dragQuestions ? 'show' : 'hide'">
            <div v-if="dragQuestions" class="content">
                <draggable :list="questions" :animation="300" :group="`questions-${rowData.id}`" handle=".hamburger-icon" @change="indicatorOrderUpdated">
                    <transition-group class="labels-container questions">
                        <div v-for="question in questions" :key="question.id" class="label-wrapper">
                            <div class="label-row">
                                <div class="left">
                                    <q-icon type="hamburger-menu" class="hamburger-icon show" color="#ADB5BD"></q-icon>
                                    <p class="name">{{ question.name }}</p>
                                </div>
                            </div>
                        </div>
                    </transition-group>
                </draggable>
            </div>
            <div v-else-if="dragReferences" class="content">

                <div v-if="references && references.length">
                    <p class="label-name"><b>Kenmerken</b></p>
                    <hr>

                    <draggable :list="references" :animation="300" :group="`references-${rowData.id}`" handle=".hamburger-icon" @change="indicatorOrderUpdated">
                        <transition-group class="labels-container questions">
                            <div v-for="reference in references" :key="reference.id" class="label-wrapper">
                                <div class="label-row">
                                    <div class="left">
                                        <q-icon type="hamburger-menu" class="hamburger-icon show" color="#ADB5BD"></q-icon>
                                        <p class="name">{{ reference.name }}</p>
                                    </div>
                                </div>
                            </div>
                        </transition-group>
                    </draggable>
                </div>
            
                <div v-if="questions && questions.length">
                    <p class="label-name"><b>Vragen</b></p>
                    <hr>

                    <draggable :list="questions" :animation="300" :group="`questions-${rowData.id}`" handle=".hamburger-icon" @change="indicatorOrderUpdated">
                        <transition-group class="labels-container questions">
                            <div v-for="question in questions" :key="question.id" class="label-wrapper">
                                <div class="label-row">
                                    <div class="left">
                                        <q-icon type="hamburger-menu" class="hamburger-icon show" color="#ADB5BD"></q-icon>
                                        <p class="name">{{ question.name }}</p>
                                    </div>
                                </div>
                            </div>
                        </transition-group>
                    </draggable>
                </div>
            </div>
            <div v-else class="content">
                <div v-if="rowData.description" class="label-description-wrapper"> 
                    <p>{{ rowData.description }}</p>
                </div>
                <div v-if="!isFormtemplatePage || referencesOnly" class="references-wrapper">
                    <q-table :columns="referenceColumns" :data="references" paddingInline="20px" class="table">
                        <template v-slot:row="{ row, column }">
                            <span v-if="column === 'name'">
                                <div class="name-wrapper">
                                    <p class="row-name ellipsis-2">{{ row[column] }}</p>
                                    <img v-if="row.masterId" class="standard-set" src="/statics/img/favicon.png" alt="">
                                </div>
                            </span>
                            <span v-if="column === 'answerTypeLabel'">
                                <p class="row-templates ellipsis-2">{{ row[column] }}</p>
                            </span>
                            <span v-else-if="column === 'required'">
                                <q-icon class="check-icon" type="check" :color="row[column] ? '#00a1ae' : 'transparent'"></q-icon>
                            </span>
                            <span v-else-if="column === 'canFillComment'">
                                <div class="final-row-container">
                                    <q-icon class="check-icon" type="check" :color="row[column] ? '#00a1ae' : 'transparent'"></q-icon>
                                    <q-tag v-if="row.status === 'disabled'" size="small" variation="danger">Inactief</q-tag>
                                    <q-button class="edit-button" variation="ghost" size="xsmall" @click="handleEditQuestion(row)">{{ disableEdit ? 'Bekijken' : 'Bewerken' }}</q-button>
                                </div>
                            </span>
                        </template>
                    </q-table>
                    <q-button v-if="!disableEdit" class="add-button" size="small" @click="handleAddReference">+ Kenmerk toevoegen</q-button>
                </div>
                <div v-if="!referencesOnly" class="questions-wrapper">
                    <q-table :columns="questionColumns" :data="questions" paddingInline="20px" class="table">
                        <template v-slot:row="{ row, column }">
                            <span v-if="column === 'name'">
                                <div class="name-wrapper">
                                    <p class="row-name ellipsis-2">{{ row[column] }}</p>
                                    <img v-if="row.masterId" class="standard-set" src="/statics/img/favicon.png" alt="">
                                </div>
                            </span>
                            <span v-if="column === 'formtemplates'">
                                <p class="row-templates ellipsis-2">{{ getTemplateNames(row[column]) }}</p>
                            </span>
                            <span v-else-if="['required','mandatory'].includes(column)">
                                <q-icon class="check-icon" type="check" :color="row[column] ? '#00a1ae' : 'transparent'"></q-icon>
                            </span>
                            <span v-else-if="column === 'canFillComment'">
                                <div class="final-row-container">
                                    <q-icon class="check-icon" type="check" :color="row[column] ? '#00a1ae' : 'transparent'"></q-icon>
                                    <q-tag v-if="row.status === 'disabled'" size="small" variation="danger">Inactief</q-tag>
                                    <q-button class="edit-button" variation="ghost" size="xsmall" @click="handleEditQuestion(row)">{{ disableEdit ? 'Bekijken' : 'Bewerken' }}</q-button>
                                </div>
                            </span>
                        </template>
                    </q-table>
                    <q-button v-if="!disableEdit" class="add-button" size="small" @click="handleAddQuestion">+ Vraag toevoegen</q-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
    name: 'collapsable-row',
    components: {
        draggable
    },
    props: {
        /* Object parameters
        /   name: String!
        /   status: String
        /   masterId: String
        /   locked: Boolean
        /   tagLabel: String
        /   description: String
        */
        rowData: {
            type: Object,
            required: true
        },
        questions: {
            type: Array,
            default() { return [] }
        },
        references: {
            type: Array,
            default() { return [] }
        },
        collapsed: {
            type: Boolean,
            default: false
        },
        // search value
        search: {
            type: String,
            default: null
        },
        // show 'tagLabel' in the rowData object
        showTag: {
            type: Boolean,
            default: false
        },
        // show 'status' in the rowData object
        showStatus: {
            type: Boolean,
            default: false
        },
        // typename of the rowData object
        dataName: {
            type: String,
            default: 'prestatie'
        },
        // typename of the context (Prestatie, Formuliertemplate, Projecttemplate)
        contextName: {
            type: String,
            default: ''
        },
        loadingComponents: {
            type: Boolean,
            default: false
        },
        dragQuestions: {
            type: Boolean,
            default: false
        },
        dragReferences: {
            type: Boolean,
            default: false
        },
        dragLabels: {
            type: Boolean,
            default: false
        },
        disableToggleRow: {
            type: Boolean,
            default: false
        },
        referencesOnly: {
            type: Boolean,
            default: false
        },
        // style the row as disabled
        disabled: {
            type: Boolean,
            default: false
        },
        noDisable: {
            type: Boolean,
            default: false
        },
        disableEdit: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean, 
            default: false
        }
    },
    data() {
        return {
            referenceColumns: [
                {
                    label: 'Kenmerk',
                    field: 'name',
                    width: '35%'
                },
                {
                    label: 'Antwoordtype',
                    field: 'answerTypeLabel',
                    width: '40%'
                },
                {
                    label: 'Verplicht',
                    field: 'required',
                    width: '10%'
                },
                {
                    label: 'Opmerkingen',
                    field: 'canFillComment',
                    width: '15%'
                },
            ],
            questionColumns: [
                {
                    label: 'Vraag',
                    field: 'name',
                    width: '45%'
                },
                {
                    label: 'Formulier',
                    field: 'formtemplates',
                    width: '30%'
                },
                {
                    label: 'Verplicht',
                    field: 'required',
                    width: '10%'
                },
                {
                    label: 'Vereist',
                    field: 'mandatory',
                    width: '10%'
                },
                {
                    label: 'Opmerkingen',
                    field: 'canFillComment',
                    width: '15%'
                },
            ],
        }
    },
    methods: {
        getRowImage(row) {
            if (row.masterId) 
                return '/statics/img/favicon.png';
        },
        toggleShowRow() {
            if (this.disableToggleRow || this.loading) return; 
            this.$emit('toggleShowRow', this.rowData)
        },
        handleAction(option) {
            this.$emit('optionSelected', option, this.rowData);
        },
        indicatorOrderUpdated() {
            this.$emit('indicatorOrderUpdated', { 
                labelId: this.rowData.id, 
                indicators: [...this.references, ...this.questions]
            });
        },
        handleAddReference() {
            this.$emit('handleAddReference', this.rowData);
        },
        handleAddQuestion() {
            this.$emit('handleAddQuestion', this.rowData);
        },
        handleEditQuestion(question) {
            this.$emit('handleEditQuestion', question)
        },
        getTemplateNames(formtemplates) {
            if(!formtemplates || formtemplates.length === 0) return ''
            const templateNames = formtemplates.map(template => {
                if(template.name.includes('form_template')) template.name = this.$t(`formtemplates.${template.name}`)
                return template.name
            });
            return templateNames.join(', ')
        }
    },
    computed: {
        isFormtemplatePage() {
            return this.$route.name === 'FormTemplateManagement'
        },
        markedName: function() {
            const re = new RegExp(this.search, 'i');
            const match = this.rowData.name.match(re);
            const newName = this.rowData.name.replace(re, `<span style="background-color: #F4B976;border-radius:3px;">${match}</span>`);
            return newName
        },
        actionOptions() {
            let options = [];

            const canEditName = !Boolean(this.rowData.masterId || this.disableEdit);
            const canEditDescription = Boolean(!canEditName);
            const canChangeStatus = Boolean(!this.noDisable && !(this.dataName === 'prestatie' && this.contextName !== 'prestatie') && !this.disableEdit);
            const canDelete = !Boolean(this.disableEdit || (this.contextName === 'formulier' && this.rowData.isMaster));

            if(canEditName) options.push({
                name: 'Naam wijzigen'
            })
            if(canEditDescription) options.push({
                name: 'Omschrijving wijzigen'
            })
            if(canChangeStatus) options.push({
                name: `${this.rowData.status === 'disabled' ? 'Activeer' : 'Deactiveer'} ${this.dataName}`
            })
            if(canDelete) options.push({
                name: `Verwijder ${this.dataName}`,
                color: 'red'
            })
            
            return options
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/qds/assets/style/_variables.scss';

.label-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 6px;
    color: #484f56;
    margin: 35px 0 15px 20px;
}

hr {
    border: 0;
    border-top: 1px solid #e0e3e5;
}

.standard-set {
    width: 18px;
    object-fit: contain;
}

.labels-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    &.questions {
        margin-top: 10px;
        margin-inline: 24px;
    }
}

.label-wrapper.disabled {
    .label-row {
        background-color: #EFEFEF;

        .left {
            .name {
                color: $color-grey-7;
            }
        }
    }
}
.label-wrapper.loading {
    .label-row {
        background-color: #EFEFEF;

        .left {
            .name {
                color: $color-grey-7;
            }
        }
        .right {
            .action-options {
                opacity: 0;
                pointer-events: none;
            }
        }
    }
}

.label-wrapper {
    scroll-margin-top: 150px;

    .label-row {
        display: flex;
        justify-content: space-between;
        padding: 14px 22px;
        border-radius: 2px;
        border: 1px solid $color-grey-3;
        box-shadow: 0px 2px 4px rgba(128, 128, 128, 0.1);
        cursor: pointer;
        user-select: none;

        .left {
            display: flex;
            align-items: center;
            gap: 12px;
            overflow: hidden;

            .hamburger-icon {
                cursor: grab;
                scale: 0.75;
                margin-left: -30px;
                transition: .2s ease;

                &.show {
                    transition: .2s ease calc(0.02s * var(--index));
                    margin-left: 0;
                }
            }

            .name {
                font-size: 16px;
                font-weight: 500;
            }
        }

        .right {
            display: flex;
            align-items: center;
            gap: 24px;

            .action-options {
                transition: opacity 0.2s ease;
            }
        }
    }

    .content-container {
        .content {
            .label-description-wrapper {
                display: flex;
                margin: 20px 20px 0px 20px;

                .label-description {
                    margin-right: 30px;
                }
            }

            .references-wrapper,
            .questions-wrapper {
                padding-block: 40px;

                .add-button {
                    margin-top: 16px;
                    margin-left: 20px;
                }
            }

            .table {
                .name-wrapper {
                    display: flex;
                    gap: 8px;
                    margin-right: 8px;
                }

                .ellipsis-2 {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .check-icon {
                    padding: 2px;
                }

                .final-row-container {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .edit-button {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        translate: 0 -50%;
                        transform-origin: right center;
                        scale: .5;
                        opacity: 0;
                        background: white;
                        box-shadow: #606060 0px 2px 8px -4px;
                        pointer-events: none;
                    }
                }

                tr:hover .final-row-container .edit-button {
                    scale: 1;
                    opacity: 1;
                    transition: opacity .2s ease .2s, scale .3s cubic-bezier(.51,1.2,.45,1.08) .2s;
                    pointer-events: all;
                }
            }
        }
    }

}

// animations

.show-loader {
    display: grid;
    transition: .3s ease;
    -webkit-transition: .3s ease;
    will-change: grid-template-rows;

    &.show {
        grid-template-rows: 1fr;
        .center {
            transition: scale .2s cubic-bezier(.51,1.4,.45,1.08) .1s;
            -webkit-transition: scale .2s cubic-bezier(.51,1.4,.45,1.08) .1s;
            scale: 1;
        }
    }
    &.hide {
        grid-template-rows: 0fr;
        .center {
            transition: scale .15s ease;
            -webkit-transition: scale .15s ease;
            scale: 0;
        }
    }
    .center {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden !important;
    }
}

.content-container {
    display: grid;
    transition: grid-template-rows .3s ease;
    -webkit-transition: grid-template-rows .3s ease;
    will-change: grid-template-rows;

    &.show {
        grid-template-rows: 1fr;
    }
    &.hide {
        grid-template-rows: 0fr;
    }

    .content {
        overflow: hidden;
    }
}

.loader {
    $loader-color: #8a8c8f;
    $loader-size: 20px;
    $loader-border-size: 3px;
    $loader-animation-duration: 700ms;
    @import '../../../components/qds/assets/loaders/loaders.scss';
    @include loader02;
    margin-block: 20px;
}

</style>