<template>
    <div id="navbar">
        <div :class="['navbar', folded ? 'folded' : 'whole']">
            <div class="top-section">
                <div class="head">
                    <img v-if="folded" class="folded-img" src="/statics/img/Qfact-logo-small.png" alt="" @click="to('/')" />
                    <div v-else class="vertical-center">
                        <img class="whole-img" src="/statics/img/Qfact-logo.svg" alt="" @click="to('/')" />
                        <navbar-notification-badge v-if="notify" :type="notify" @click="emitNotificationClick" />
                    </div>
                </div>
                <div class="body">
                    <div v-if="hasActiveEmviProducts" class="groslijst-buttons-container">
                        <q-button class="groslijsten-wrapper" :class="{ small: folded }" size="medium" variation="primary" @click="toEmvi" > 
                            <div class="groslijsten-button">
                                <img class="emvi-arrow" src="/statics/img/back.png" />
                                <p class="button-text">{{ folded ? 'G' : 'Naar g' }}roslijsten</p>
                                <img v-if="!folded" class="emvi-logo" src="/statics/img/logo-emvi-white1.png" />
                            </div>
                        </q-button>
                    </div>
                    <div class="options-wrapper" :class="{ folded }">
                        <div
                            v-for="option in navbarOptions"
                            :key="option.to"
                            :class="['option', 'bottom', itemActive(option.to) ? 'active' : '', { folded }]"
                            @click="to(option.to)"
                        >
                            <div class="main-option">
                                <div class="left-content">
                                    <div class="icon_" :class="{ active: option.active, 'move-up': folded }">
                                        <q-icon :type="optionIcon(option)" width="20" height="20" />
                                    </div>
                                    <div class="label" :class="folded ? 'small-title' : ''">
                                        <span :class="itemActive(option.to) ? 'active' : ''">{{ option.label }}</span>
                                    </div>
                                </div>
                                <div v-if="option.badge || (option.subOptions && !folded)" @click.stop="toggle(option.to)">
                                    <div v-if="option.badge" class="notification">
                                        <q-badge :value="option.badge"></q-badge>
                                    </div>
                                    <q-icon
                                        v-else-if="option.subOptions.length > 0"
                                        type="chevronDown"
                                        color="#ADB5BD"
                                        style="width: 16px; height:16px;"
                                        class="sub-items-icon"
                                        :class="[openedSubOptions.includes(option.to) ? 'sub-opened' : '']"
                                        data-testid="collapse-sub-button"
                                    ></q-icon>
                                </div>
                            </div>
                            <div
                                v-if="
                                    option.subOptions &&
                                        option.subOptions.length > 0 &&
                                        !folded
                                "
                                class="sub-options"
                                :style="openedSubOptions.includes(option.to) ? 'height:' + (32 * option.subOptions.length + 8) + 'px' : 'height:0px'"
                            >
                                <div
                                    v-for="subOption in option.subOptions"
                                    :key="subOption.to"
                                    @click.stop="to(subOption.to)"
                                    :class="['option', subItemActive(subOption.to) ? 'sub-active' : '']"
                                >
                                    <span>{{ subOption.label }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bottom-section">
                <div
                    class="collapse-button"
                    @click="toggleFold"
                    @mouseenter="showCollapseLabel = true"
                    @mouseleave="showCollapseLabel = false"
                    data-testid="collapse-button"
                >
                    <p class="collapse-label" v-if="!folded && showCollapseLabel">Menu inklappen</p>
                    <q-icon
                        :class="[folded ? 'point-right' : 'point-left']"
                        type="chevronDown"
                        color="#ADB5BD"
                        width="22"
                        height="22"
                    ></q-icon>
                </div>
            </div>
        </div>
        <q-popup v-if="showProjectModal" id="modal" width="60%" height="70%" @close="showProjectModal = false">
            <o-related-projects-overview :selectedProject="selectedProject" @projectSelected="handleProjectSelected"></o-related-projects-overview>
        </q-popup>
    </div>
</template>

<script>
import NavbarNotificationBadge from '@/components/NavbarNotificationBadge.vue';
import ReleaseNotesModal from '@/components/infoModals/ReleaseNotesModal.vue';

export default {
    name: 'q-navbar',
    status: 'prototype',
    release: '0.0.1',
    components: { NavbarNotificationBadge, ReleaseNotesModal },
    props: {
        /**
         *Specifies options in navbar structure:
         * - to: router route (/projects)
         * - label: as shown in the navbar
         * - subOptions: array of sub options structure:
         *      - to: router route (/projects/settings)
         *      - label: as shown in navbar
         */
        options: {
            type: Array
        },
        value: {
            type: Boolean,
            default: false
        },
        notify: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            current: '',
            folded: this.value,
            showCollapseLabel: false,
            openedSubOptions: [],
            selectedProject: null,
            showProjectModal: false
        };
    },
    computed: {
        navbarOptions() {
            return this.options;
        }, 
        hasActiveEmviProducts() {
            if (this.$store == null) return false;
            return this.$store.getters.hasActiveEmviProducts;
        }
    },
    methods: {
        toggle(toOpen) {
            let v = this;

            if (this.openedSubOptions.includes(toOpen))
                this.openedSubOptions.forEach((option, key) => {
                    if (option === toOpen) v.openedSubOptions.splice(key, 1);
                });
            else this.openedSubOptions.push(toOpen);
        },
        to(to) {
            if (!this.openedSubOptions.includes(to)) this.openedSubOptions.push(to);
            if (to == this.current) return;
            else this.$router.push(to);
        },
        optionIcon(option) {
            const activeIcon = option.active || option.icon;

            const currentTo = this.current.split('/')[1];
            if(!option.to) return option.icon
            const to = option.to.split('/')[1];

            return currentTo === to ? activeIcon : option.icon;
        },
        itemActive(to) {
            if(!to) return false
            to = to.split('/')[1].trim();
            const current = this.current.split('/')[1].trim();

            if(to === 'forms' && current === 'enquetes') return true
            if(to === 'settings' && current === 'manage') return true

            return to == current;
        },
        subItemActive(to) {
            let current = this.current;
            if(current.includes('?')) current = current.split('?')[0];
            if(to.includes('?')) to = to.split('?')[0];

            return to === current;
        },
        toggleFold() {
            this.folded = !this.folded;
            this.openedSubOptions = [];

            this.$emit('input', this.folded);
        },
        emitNotificationClick() {
            this.$emit('notificationClick');
        },
        openProjectModal() {
            this.showProjectModal = true;
        },
        handleProjectSelected(project) {
            this.selectedProject = project;
            this.showProjectModal = false;
            this.$emit('projectSelected', this.selectedProject)
        },
        logout() {
            this.$emit('logout');
        },
        toEmvi() {
            const { hasQfactAccess, getJwt } = this.$store.getters;
            const targetUrl = `${process.env.EMVI_APP_URL}/authentication/login?token=${getJwt}&qfact_access=${hasQfactAccess}`;
            window.location = targetUrl;
        }
    },
    watch: {
        $route: function() {
            if (!this.$route) return;
            this.current = this.$route.path;
        }
    },
    created() {
        if (!this.$route) return;
        this.current = this.$route.path;
    }
};
</script>

<style lang="scss" scoped>
@import '../../assets/style/_variables.scss';
@import '../../assets/style/fonts/fonts.css';
@import '@/components/qds/assets/style/style.scss';

$gradient: linear-gradient(270deg, rgba(0, 161, 173, 1) 0%, rgba(0, 106, 148, 1) 46%, rgba(102, 36, 130, 1) 100%);


.navbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    width: calc(100% - 1px);

    font-family: 'Gotham';
    color: #373836;
    background-color: #f8f9fa;
    border-right: 1px solid #dee2e6;

    .bottom-section {
        .profile-info {
            display: none;
            align-items: center;
            gap: 8px;
            overflow: hidden;

            .flex-column {
                display: flex;
                flex-direction: column;
                gap: 4px;

                .name {
                    font-weight: 500;
                    font-size: 15px;
                }
                .function {
                    font-size: 14px;
                }
            }
        }
        
        .collapse-button {
            height: 55px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-inline: 16px;
            cursor: pointer;

            & .collapse-label {
                font-size: 10px;
                font-weight: 500;
                color: $color-grey-5;
                margin-right: 7px;
                opacity: 0;
                animation: fadeIn 200ms 1 300ms;
                -webkit-animation-fill-mode: forwards; /* Chrome 16+, Safari 4+ */
                -moz-animation-fill-mode: forwards; /* FF 5+ */
                -o-animation-fill-mode: forwards; /* Not implemented yet */
                -ms-animation-fill-mode: forwards; /* IE 10+ */
                animation-fill-mode: forwards;
            }

            & .point-left {
                margin-bottom: -7px;
                padding: 0px 4px;
                transform: rotate(90deg);
            }

            & .point-right {
                margin-top: -8px;
                transform: rotate(-90deg);
            }
        }
    }
}


.head {
    padding-block: 28px;
    width: 100%;

    .whole-img {
        padding-inline: 15px;
        height: 35px;
        cursor: pointer;
    }

    .folded-img {
        padding-inline: 13px;
        padding-block: 6px 6px;
        height: 35px;
        cursor: pointer;
    }
}

.body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    --gap: 4px;

    .options-wrapper {
        display: flex;
        flex-direction: column;
        width: calc(100% - 16px);
        gap: var(--gap);

        &.folded {
            width: calc(100% - var(--gap) * 2);
        }

        .option {
            position: relative;
            user-select: none;
            -webkit-user-select: none;
            border-radius: 8px;
            cursor: pointer;

            &:hover {
                background: darken(#f1f1f3, 2%);
            }
            &.active {
                box-shadow: inset 0 0 6px darken(#f1f1f3, 3%);
                background: darken(#f1f1f3, 2%);

                > span {
                    color: #00a1ae;
                }
            }

            &.folded {
                .main-option {
                    .left-content {
                        margin-inline: auto;
                        width: 100%;
                    }
                    .notification {
                        right: -12px;
                    }
                }
            }

            .main-option {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 50px;

                .left-content {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    cursor: pointer;

                    .label {
                        font-size: 15px;
                        line-height: 24px;
                        font-weight: 500;
                        transition: .3s ease;
                        -webkit-user-select: none;

                        .active {
                            position: relative;
                            color: #00a1ae;

                            &:before{
                                content: "";
                                position: absolute;
                                inset: 2px;
                                background: #00a1ae;
                                filter: blur(6px);
                                opacity: .1;
                            }
                        }

                        & span {
                            transition: color 200ms ease;
                        }
                    }

                    .small-title {
                        position: relative;
                        width: 100%;
                        text-align: center;
                        margin-left: -100%;
                        margin-bottom: -30px;
                        font-size: 8px !important;
                        font-weight: 600 !important;
                        color: $color-grey-7;
                        user-select: none;
                        -webkit-user-select: none;
                        transition: margin .3s ease;

                        &.active {
                            color: #00a1ae;
                            &:before{
                                content: "";
                                position: absolute;
                                inset: 2px;
                                background: #00a1ae;
                                filter: blur(6px);
                                opacity: .1;
                            }
                        }
                    }

                    .icon_ {
                        position: relative;
                        padding-inline: 16px;
                        transition: transform .3s ease .05s;

                        &.move-up {
                            transform: translateY(-20%);
                        }

                        &.active {
                            &:before{
                                content: "";
                                position: absolute;
                                inset: 2px;
                                background: #00a1ae;
                                filter: blur(6px);
                                opacity: .1;
                            }
                        }
                    }
                }

                .notification {
                    position: absolute;
                    top: 50%;
                    right: 16px;
                    translate: 0 -50%;
                    pointer-events: none;
                    margin-top: -1px;
                }
            }

            .sub-options {
                transition: height .3s ease;
                overflow: hidden;

                .option {
                    cursor: pointer;
                    font-size: 14px;
                    line-height: 32px;
                    font-weight: 500;
                    padding: 0 12px 0 53px;
                    transition: color 200ms ease;
                    display: flex;
                    justify-content: space-between;
                    background: none;
                    user-select: none;
                    -webkit-user-select: none;

                    span:hover {
                        transition: color 200ms ease;
                        color: #00a1ae;
                    }

                    &:last-child {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }

}

.sub-items-icon {
    cursor: pointer;
    position: absolute;
    right: 14px;
    top: 50%;
    translate: 0 -45%;
    transition: transform .3s ease;
    transform-origin: 50% 45%;
}

.sub-opened {
    transform: rotate(180deg);
}

.active {
    .label {
        color: #00a1ae;
    }
    .icon_ {
        color: #00a1ae;
    }
}

.sub-active {
    color: #00a1ae;
}

.bottom {
    // border-bottom: 1px solid #dee2e6;
}

.groslijst-buttons-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.groslijsten-wrapper {
    width: calc(100% - 16px);
    height: 40px;
    padding-left: 12px !important;
    padding-right: 12px !important;

    .groslijsten-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;

        .emvi-arrow {
            width: 16px;
        }

        .button-text  {
            font-weight: 500;
        }
    }

    &.small {
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - var(--gap) * 2);

        .groslijsten-button {
            gap: 8px;
            flex-direction: column;

            .emvi-arrow {
                scale: 1.1;
            }

            .button-text {
                font-size: 8px;
                line-height: 8px;
            }
        }
    }
}

.emvi-arrow {
    transition: scale .3s ease;
}

.groslijsten-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.groslijsten-text {
    margin-left: -45px;
    margin-bottom: -17px;;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    } 100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    } 100% {
        opacity: 0;
    }
}

</style>

<docs>
  ```jsx
  <div style="height: 1024px; border: 1px solid black">
    <q-navbar> </q-navbar>
    
  </div>
  ```
</docs>