import gql from 'graphql-tag'; 

export const GET_PROJECT_EVENTS = gql`
    query ProjectEventFeed {
        projectActivityFeed {
            streamId
            eventId
            eventType
            timestamp
            object
            metadata
        }
    }
`;

export const GET_FORM_EVENTS = gql`
    query FormEventFeed {
        formActivityFeed {
            streamId
            eventId
            eventType
            timestamp
            object
            metadata
        }
    }
`;

// Roles

export const ROLES = gql`
    query Roles($where: JSON, $first: Int, $skip: Int, $sort: [String]) {
        roles(first: $first, skip: $skip, where: $where, sort: $sort) {
            id
            name
            status
            permissions {
                type
                subContext
                permissions
            }
        }
    }
`;
// Roles

export const PERMISSIONS = gql`
    query Permissions {
        permissions {
            type
            description
            subContexts
            permissions
        }
    }
`;

// Indicators

export const GET_INDICATORS = gql`
    query Indicators($first: Int, $skip: Int, $where: JSON, $sort: String) {
        indicators(first: $first, skip: $skip, where: $where, sort: [$sort]) {
            id
            name
            description
            isMaster
            masterId
            answerType
            displayType
            labelIds
            type
            labels {
                id
                name
            }
            answerValues {
                value
                label
            }
            status
        }
    }
`;

export const GET_INDICATOR = gql`
    query Indicator($id: String!) {
        indicator(id: $id) {
            id
            name
            description
            isMaster
            masterId
            answerType
            displayType
            unit
            labelIds
            type
            labels {
                id
                name
            }
            answerValues {
                value
                label
            }
            status
        }
    }
`;

export const GET_INDICATORS_MINIMAL = gql`
     query Indicators($first: Int, $skip: Int, $where: JSON, $sort: String) {
        indicators(first: $first, skip: $skip, where: $where, sort: [$sort]) {
            id            
        }
    }
`;

export const GET_INDICATORS_WITH_TEMPLATES = gql`
    query IndicatorsWithTemplates($organisationId: String!, $first: Int, $skip: Int, $where: JSON, $sort: String) {
        indicators_with_templates(organisationId: $organisationId, first: $first, skip: $skip, where: $where, sort: [$sort]) {
            id
            name
            index
            description
            isMaster
            masterId
            answerType
            displayType
            type
            labelIds
            context {
                name
            }
            answerValues {
                value
                label
                status
            }
            formtemplateIds
            formtemplates {
                id
                name
            }
            projecttemplateIds
            projecttemplates {
                id
                name
            }
            required
            mandatory
            canFillComment
            canFillNotApplicable
            showScore
            unit
            status
            index
        }
    }
`;

export const GET_VALUES = gql`
    query VALUES($first: Int, $skip: Int, $where: JSON, $sort: String) {
        values(first: $first, skip: $skip, where: $where, sort: [$sort]) {
            id
            value
        }
    }
`;

export const GET_LABELS = gql`
    query Labels($first: Int, $skip: Int, $where: JSON, $sort: [String]) {
        labels(first: $first, skip: $skip, where: $where, sort: $sort) {
            id
            name
            description
            masterId
            status
            order
        }
    }
`

// Project readmodel

export const GET_PROJECT_READMODELS = gql`
    query ProjectReadmodels($first: Int, $skip: Int, $where: JSON, $sort: String) {
        projectReadmodels(first: $first, skip: $skip, where: $where, sort: [$sort]) {
            id
            clientAverage
            project {
                id
                name
                status
                norm
                creationDate
                finishedDate
                contractor {
                    id
                    name
                }
                client {
                    id
                    name
                }
                forms {
                    id
                    name
                    status
                }
            }
            info {
                status
            }
        }
    }
`;

export const PROJECT_ANALYSE_QUERY = gql`
    query ProjectAnalyseQuery(
        $first: Int
        $skip: Int
        $where: JSON
        $sort: [String]
        $postcode: String
        $radiusTo: Int
    ) {
        projectanalyse(
            first: $first
            skip: $skip
            where: $where
            sort: $sort
            postcode: $postcode
            radiusTo: $radiusTo
        ) {
            id
            clientAverage
            project {
                id
                name
                status
                norm
                creationDate
                finishedDate
                contractorId
                contractor {
                    id
                    name
                }
                clientId
                client {
                    id
                    name
                }
                forms {
                    id
                    name
                    status
                }
                members {
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
            forms {
                formType
                components {
                    masterIndicatorId
                    responses {
                        value
                    }
                }
            }
        }
    }
`;

export const PROJECT_ANALYSE_QUERY_MINIMAL = gql`
    query ProjectAnalyseQuery(
        $where: JSON
        $sort: [String]
        $postcode: String
        $radiusTo: Int
    ) {
        projectanalyse(
            where: $where
            sort: $sort
            postcode: $postcode
            radiusTo: $radiusTo
        ) {
            id
            info {
                name
            }
        }
    }
`;

export const PROJECT_ANALYSE_QUERY_FILTEROPTIONS = gql`
    query ProjectAnalyseQuery(
        $where: JSON
        $sort: [String]
        $postcode: String
        $radiusTo: Int
    ) {
        projectanalyse(
            where: $where
            sort: $sort
            postcode: $postcode
            radiusTo: $radiusTo
        ) {
            id
            info {
                name
            }
            project {
                client {
                    id
                    name
                }
                contractor {
                    id
                    name
                }
            }
            
        }
    }
`;

export const GET_PROJECT_FORMS = gql`
    query ProjectForms($id: String!, $projectJwt: String) {
        project(id: $id, projectJwt: $projectJwt) {
            id
            organisationId
            settings
            forms {
                id
                name
                type
                status
                statusLabel
                finishedBy {
                    id
                    firstName
                    lastName
                }
                scoreData {
                    average
                }
                responseIds
                responses {
                    id
                    emetMeta {
                        userName
                        email
                    }
                    values {
                        value
                    }
                }
                template {
                    id
                    name
                    masterId
                    type
                    answerType
                }
                companions {
                    id
                    status
                    scoreData {
                        average
                    }
                    responseIds
                }
                organisationId
                finishedDate
            }
            formIds
        }
    }
`;

export const GET_PROJECT = gql`
    query Project($id: String!, $projectJwt: String!) {
        project(id: $id, projectJwt: $projectJwt) {
            id
            name
            status
            clientStatus
            contractorStatus
            settings
            templates {
                id
                name
            }
            organisationId
            finishedDate
            contractor {
                id
                name
                logo
            }
            client {
                id
                name
                logo
            }
            forms {
                id
                name
                organisationId
                score
                status
                statusLabel
            }
            formIds
            members {
                userId
                user {
                    id
                    firstName
                    lastName
                    avatar
                }
                status
                roles
                email
                organisationId
                organisation {
                    id
                    name
                    logo
                }
            }
            references {
                id
                organisationId
                name
                components {
                    id
                    disabled
                    display
                    indicatorId
                    indicator {
                        id
                        name
                        unit
                        type
                        required
                        masterId
                        answerType
                        displayType
                        description
                        labelIds
                        answerValues {
                            value
                            label
                        }
                        context {
                            id
                            name
                            description
                            default
                        }
                        organisationId
                        protectionLevel
                    }
                }
                template {
                    id
                    organisationId
                }
                responses {
                    id
                    values {
                        id
                        indicatorId
                        componentId
                        masterIndicatorId
                        value
                    }
                }
                groups
            }
            crowMeta
            pdHybridmode
            hybridmode
            pdMigrated
            hasChosenCrowFlow
            usesCrowFlow
            private
        }
    }
`;

export const GET_PROJECT_STATUS = gql`
    query Project($id: String!, $projectJwt: String!) {
        project(id: $id, projectJwt: $projectJwt) {
            status
            contractorStatus 
            clientStatus
        }
    }
`;

export const ACCESS_PROJECT = gql`
    query AccessProject($id: String!) {
        project_access(id: $id) {
            jwt
            project {
                id
                name
                clientProjectName
                contractorProjectName
                status
                clientStatus
                contractorStatus
                settings
                templates {
                    id
                    name
                }
                organisationId
                finishedDate
                contractor {
                    id
                    name
                    logo
                }
                client {
                    id
                    name
                    logo
                }
                forms {
                    id
                    name
                    status
                    type
                    organisationId
                    scoreData {
                        average
                    }
                    components {
                        id
                        type
                    }
                    finishedDate
                    template {
                        id 
                        masterId
                        type
                        answerType
                    }
                    companions {
                        id
                        scoreData {
                            average
                        }
                        status
                    }
                }
                formIds
                members {
                    userId
                    user {
                        id
                        firstName
                        lastName
                        avatar
                    }
                    status
                    roles
                    email
                    organisationId
                    organisation {
                        id
                        name
                        logo
                    }
                }
                references {
                    id
                    organisationId
                    name
                    components {
                        id
                        disabled
                        display
                        indicatorId
                        indicator {
                            id
                            name
                            index
                            unit
                            masterId
                            required
                            answerType
                            displayType
                            description
                            labelIds
                            type
                            answerValues {
                                value
                                label
                            }
                            context {
                                id
                                name
                                description
                                default
                                order
                            }
                            organisationId
                            protectionLevel
                        }
                    }
                    template {
                        id
                        organisationId
                    }
                    responses {
                        id
                        values {
                            id
                            indicatorId
                            componentId
                            masterIndicatorId
                            value
                        }
                    }
                    groups
                }
                crowMeta
                pdHybridmode
                hybridmode
                pdMigrated
                hasChosenCrowFlow
                usesCrowFlow
                private
            }
        }
    }
`;

export const GET_PROJECT_REFERENCES = gql`
    query ProjectReferences($id: String!, $projectJwt: String!) {
        project(id: $id, projectJwt: $projectJwt) {
            references {
                id
                organisationId
                name
                components {
                    id
                    disabled
                    display
                    indicatorId
                    indicator {
                        id
                        name
                        unit
                        index
                        masterId
                        required
                        answerType
                        displayType
                        description
                        projecttemplateIds
                        labelIds
                        type
                        answerValues {
                            value
                            label
                        }
                        context {
                            id
                            name
                            description
                            default
                            order
                        }
                        organisationId
                        protectionLevel
                    }
                }
                template {
                    id
                    organisationId
                }
                responses {
                    id
                    values {
                        id
                        indicatorId
                        componentId
                        masterIndicatorId
                        value
                    }
                }
                groups
            }
        }
    }
`;

// Form

export const GET_FORM = gql`
    query Form($id: String!) {
        form(id: $id) {
            id
            name
            status
            creationDate
            finishedDate
            agreedByUserId
            hasBeenDisagreed
            projectId
            project {
                id
                name
                client {
                    id
                    name
                }
                contractor {
                    id
                    name
                }
                members {
                    userId
                    organisationId
                    roles
                }
                references {
                    id
                    responses {
                        id                        
                    }                    
                }
                organisationId
                crowMeta
            }
            components {
                id
                type
                styling
                items {
                    type
                    source
                    width
                    height
                    text
                    averageIds
                }
                indicator {
                    id
                    masterId
                    name
                    answerType
                    answerValues {
                        value
                        label
                    }
                    settings
                }
            }
            responses {
                id
                values {
                    id
                    indicatorId
                    value
                    satisfied
                    improvement
                    agreement
                    componentId
                }
            }
            organisation {
                name
            }
            companions {
                id
                status
                hasBeenDisagreed
                components {
                    id
                    type
                    styling
                    items {
                        type
                        source
                        width
                        height
                        text
                        averageIds
                    }
                    indicator {
                        id
                        name
                        answerType
                        answerValues {
                            value
                            label
                        }
                        settings
                    }
                }
                responses {
                    values {
                        id
                        indicatorId
                        value
                        satisfied
                        improvement
                        agreement
                        componentId
                    }
                }
                organisation {
                    name
                }
            }
        }
    }
`;

export const GET_FORM_SURVEY = gql`
    query form($id: String!) {
        form(id: $id) {
            id
            name
            description
            creationDate
            createdBy {
                firstName
                lastName
            }
            finishedDate
            finishedBy {
                id
                firstName
                lastName
            }
            status
            project {
                id
                name
            }
            organisation {
                users {
                    status
                    user {
                        id
                        firstName
                        lastName
                        avatar
                        email
                    }
                }
            }
            settings {
                shareToken
                note
                configurationId
                attendees {
                    id
                    avatar
                    firstName
                    lastName
                    email
                }
                survey {
                    sendDate
                    availability {
                        from
                        to
                    }
                    completionMessageEnabled
                    completionMessage
                    startMessageEnabled
                    startMessage
                    enableNameQuestion
                    enableEmailQuestion
                    enableFunctionQuestion
                    errorExpiredFormWithoutRespondents
                    hasCheckedFormQuestionsStep
                }
            }
            components {
                id
                type
                enabled
                description
                order
                indicator {
                    id
                    name
                    answerType
                    displayType
                    showScore
                    unit
                    required
                    mandatory
                    activeAnswerValues {
                        value
                        label
                        status
                    }
                    labelIds
                    context {
                        id
                        name
                    }
                }
            }
            labels {
                id
                name
            }
            responses {
                id
                emetMeta {
                    userName
                    email
                    function
                }
                values {
                    id
                    indicator {
                        id
                    }
                    value
                    satisfied
                    improvement
                    agreement
                    componentId
                    valueTimestamp
                }
                scoreData {
                    average
                    indicatorScores {
                        indicatorId
                        average
                        weight
                    }
                    labelScores {
                        labelId
                        average
                        weight
                    }
                }
                timestamp
            }
            scoreData {
                average
                indicatorScores {
                    indicatorId
                    average
                    weight
                }
                labelScores {
                    labelId
                    average
                    weight
                }
            }
            template {
                id
                labels {
                    id
                }
                components {
                    id
                    indicatorId
                }
            }
        }
    }
`;

export const GET_SURVEY = gql`
    query survey($shareToken: String!) {
        survey(shareToken: $shareToken) {
            id
            name
            status
            description
            project {
                id
                name
            }
            settings {
                shareToken
                note
                attendees {
                    id
                    avatar
                    firstName
                    lastName
                }
                survey {
                    sendDate
                    availability {
                        from
                        to
                    }
                    completionMessageEnabled
                    parsedCompletionMessage
                    startMessageEnabled
                    parsedStartMessage
                    enableNameQuestion
                    enableEmailQuestion
                    enableFunctionQuestion
                }
            }
            components {
                id
                type
                enabled
                required
                description
                indicator {
                    id
                    name
                    mandatory
                    canFillComment
                    canFillNotApplicable
                    showScore
                    answerType
                    displayType
                    unit
                    required
                    description
                    answerValues {
                        value
                        label
                        status
                    }
                    labelIds
                    context {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const GET_RESPONSES = gql`
    query responses($where: JSON) {
        responses(where: $where) {
            id
            formId
            emetMeta {
                userName
                email
            }
        }
    }
`

export const GET_FORM_TEMPLATES = gql`
    query formtemplates($where: JSON) {
        formtemplates(where: $where) {
            id
            name
            description
            answerType
            type
            masterId
            components {
                id
                indicatorId
            }
            status
        }
    }
`;

export const GET_PROJECT_TEMPLATES = gql`
    query projecttemplates($where: JSON) {
        projecttemplates(where: $where){
            id
            name
            description
            isMaster
            status
        }
    }
`;

// Tokens

export const GET_TOKENS = gql`
    query tokens($projectId: String) {
        tokensWithValue(projectId: $projectId) {
            label
            tokenLabel
            componentId
            value
        }
    }
`

// Projects

export const GET_PROJECTS = gql`
    query getProjects($where: JSON, $sort: [String], $first: Int, $skip: Int, $caseSensitive: Boolean) {
        projects(where: $where, sort: $sort, first: $first, skip: $skip, caseSensitive: $caseSensitive) {
            id
            name
            clientProjectName
            contractorProjectName
            status
            clientStatus
            contractorStatus
            usesCrowFlow
            norm
            creationDate
            finishedDate
            contractor {
                id
                name
            }
            client {
                id
                name
            }
            forms {
                id
                name
                status
            }
            members {
                userId
            }
            crowMeta
        }
    }
`;

export const GET_PROJECTS_MINIMAL = gql`
    query getProjectsMinimal($where: JSON, $caseSensitive: Boolean) {
        projects(where: $where, caseSensitive: $caseSensitive) {
            id
            name
            clientProjectName
            contractorProjectName
            clientId
            contractorId
            status
            clientStatus
            contractorStatus
        }
    }
`;

// Forms

export const GET_FORMS = gql`
    query GetForms($first: Int, $skip: Int, $where: JSON) {
        forms(first: $first, skip: $skip, where: $where) {
            id
            components {
                id
                indicator {
                    id
                    name
                    labelIds
                    labels {
                        id 
                        name
                    }
                }
            }
            project {
                name
                contractor {
                    name
                }
                client {
                    name
                }
            }
            creationDate
            status
        }
    }
`;

export const GET_FORMS_WITHOUT_REFERENCES = gql`
    query GetFormsWithoutReferences($first: Int, $skip: Int, $where: JSON, $projectsWhere: JSON, $sort: [String]) {
        formsWithoutReferences(first: $first, skip: $skip, where: $where, projectsWhere: $projectsWhere, sort: $sort) {
            id
            name
            type
            formType
            template {
                id
                isMaster
                name
            }
            project {
                id
                name
                clientProjectName
                contractorProjectName
                contractor {
                    id
                    name
                }
                client {
                    id
                    name
                }
            }
            scoreData {
                average
            }
            responseIds
            creationDate
            status
            statusLabel
        }
    }
`;

export const GET_FORMS_MINIMAL = gql`
    query getFormsMinimal($where: JSON, $projectsWhere: JSON, $first: Int, $skip: Int) {
        formsWithoutReferences(where: $where, projectsWhere: $projectsWhere, first: $first, skip: $skip) {
            id
            name
            status
            statusLabel
            projectId
            templateId
            responseIds
        }
    }
`

// Authentication

export const USER = gql`
    query User($id: String!) {
        user(id: $id) {
            id
            firstName
            lastName
            email
            status
            avatar
            phone
            changeEmail
            organisations {
                status
                organisation {
                    id
                    name
                    status
                    address {
                        line1
                        line2
                        city
                    }
                }
            }
        }
    }
`;

export const GET_USER_ORGANISATIONS = gql`
    query UserOrganisations($id: String!) {
        user(id: $id) {
            id
            organisations {
                organisationId
                organisation {
                    id
                    name
                    logo
                }
                roleId
                status
            }
        }
    }
`;

export const ORGANISATION_USERS = gql`
    query OrganisationUsers($where: JSON, $sort: [String], $first: Int, $skip: Int) {
        users(where: $where, sort: $sort, first: $first, skip: $skip) {
            id
            avatar
            firstName
            lastName
            email
            status
            phone
        }
    }
`;

export const GET_USER_LOGIN_SETTINGS = gql`
    query getUserLoginSettings($id: String!) {
        user(id: $id) {
            id
            firstName
            lastName
            email
            status
            avatar
            phone
            changeEmail
            organisations {
                organisationId
                roleId
                status
            }
            settings {
                login {
                    twofactorEnabled
                    twofactorSecret
                    autoLogin
                    lastLoginOrganisation
                }
            }
        }
    }
`;

export const GET_FIRSTNAME = gql`
    query GetFirstName($email: String!) {
        user_getFirstName(email: $email) # returns firstname as String
    }
`;

export const GET_USER = gql`
    query getUser($id: String!) {
        user(id: $id) {
            id
            firstName
            lastName
            email
            status
            avatar
            phone
            changeEmail
            organisations {
                organisationId
                roleId
                status
            }
        }
    }
`;

export const CHECK_LEGAL_ORG = gql`
    query checkLegalOrg($registrationId: String!, $branchId: String, $type: String!) {
        org_legalExists(registrationId: $registrationId, branchId: $branchId, type: $type)
    }
`;

// This query returns organisations which are related to the specified user
export const ORG_MINE = gql`
    query org_mine {
        org_mine {
            id
            type
            logo
            name
            status
            address {
                city
                line1
                line2
            }
            users {
                userId
                status
                role {
                    name
                }
            }
            products {
                slug
            }
        }
    }
`;

export const ORGANISATION = gql`
    query Organisation($organisationId: String!) {
        organisation(id: $organisationId) {
            id
            name
            logo
            type
            address {
                line1
                line2
                zip
                city
                country
            }
            status
            phones {
                phone
                name
                main
            }
            legal {
                name
                country
                branchId
                registrationId
            }
            products {
                name
                slug
                enabled
                modules
                settings
            }
            users {
                status
                userId
                roleId
                email
                user {
                    id
                    email
                    firstName
                    lastName
                    avatar
                }

            }
            childs {
                id
                name
                legal {
                    registrationId
                    branchId
                }
            }
            servicesAverage
            worksAverage
            mainConfigurationId
        }
    }
`;

export const GET_ORGANISATION_USERS = gql`
    query GetOrganisationUsers($organisationId: String!) {
        organisation(id: $organisationId) {
            id
            users {
                user {
                    id
                    firstName
                    lastName
                    avatar
                    email
                    organisations {
                        organisationId
                        roleId
                    }
                }
                role {
                    id
                    name
                }
                status
            }
        }
    }
`;
export const ORGANISATION_USERS_MINIMAL = gql`
    query OrganisationUsersMinimal($organisationId: String!) {
        organisation(id: $organisationId) {
            id
            users {
                userId
                roleId
                role {
                    name
                }
                status
                email
                twofactorEnabled
            }
            settings {
                login {
                    twofactor
                }
            }
        }
    }
`;

export const GET_ORGANISATION_BRANCHES = gql`
    query Organisation($organisationId: String!) {
        organisation(id: $organisationId) {
            id
            logo
            childs {
                id
                name
                address {
                    line1
                    zip
                    city
                }
            }
        }
    }
`;

export const KVK_COMPANIES = gql`
    query kvkCompanies($term: String!, $includeBranches: Boolean, $page: Int) {
        kvk_companies(term: $term, includeBranches: $includeBranches, page: $page) {
            kvkNumber
            branchNumber
            tradeNames {
                businessName
            }
            isBranch
            isMainBranch
            addresses {
                type
                street
                postalCode
                houseNumber
                houseNumberAddition
                city
                country
            }
            websites
            mainExists {
                exists
                id
            }
            branchExists {
                exists
                id
            }
        }
    }
`;

export const ORGANISATIONS = gql`
    query Organisations($first: Int, $skip: Int, $where: JSON, $sort: String, $caseSensitive: Boolean) {
        organisations(
            first: $first
            skip: $skip
            where: $where
            sort: [$sort]
            caseSensitive: $caseSensitive
        ) {
            id
            name
            logo
            type
            address {
                line1
                zip
                city
                country
            }
            status
            phones {
                phone
                name
                main
            }
            legal {
                name
                country
                branchId
                registrationId
            }
            products {
                name
                slug
            }
            finishedProjects
            users {
                userId
            }
            childs {
                id
                name
                legal {
                    registrationId
                    branchId
                }
            }
            worksAverage
            servicesAverage
            worksTotal
            servicesTotal
        }
    }
`;

export const ORGANISATIONS_COMPANIES_PAGE = gql`
    query Organisations($first: Int, $skip: Int, $where: JSON, $sort: String, $caseSensitive: Boolean) {
        organisations(
            first: $first
            skip: $skip
            where: $where
            sort: [$sort]
            caseSensitive: $caseSensitive
        ) {
            id
            name
            logo
            type
            address {
                line1
                zip
                city
                country
            }
            status
            phones {
                phone
                name
                main
            }
            legal {
                name
                country
                branchId
                registrationId
            }
            products {
                name
                slug
            }
            finishedProjects
            users {
                userId
            }
            worksAverage
            servicesAverage
            worksTotal
            servicesTotal
        }
    }
`;


export const ORGANISATIONS_MINIMAL_COMPANIES_PAGE = gql`
    query Organisations($first: Int, $skip: Int, $where: JSON, $sort: [String], $caseSensitive: Boolean) {
        organisations(first: $first, skip: $skip, where: $where, sort: $sort, caseSensitive: $caseSensitive) {
            id
            name
            legal {
                registrationId
            }
            products {
                slug
                enabled
            }
        }
    }
`;

export const ORGANISATIONS_MINIMAL_CHILDS_COMPANIES_PAGE = gql`
    query Organisations($first: Int, $skip: Int, $where: JSON, $sort: [String], $caseSensitive: Boolean) {
        organisations(first: $first, skip: $skip, where: $where, sort: $sort, caseSensitive: $caseSensitive) {
            id
            name
            childs {
                parentId
            }
        }
    }
`;

export const ORG_SSO_SLUG = gql`
    query OrgSsoSlug($slug: String!) {
        organisations(where: { settings_sso_slug: $slug }) {
            id
            name
        }
    }
`;

export const ORG_SSO_DOMAIN = gql`
    query OrgSsoSlug($domain: String!) {
        organisations(where: { settings_sso_customDomain: $domain }) {
            id
            name
        }
    }
`;

export const ORG_SETTINGS = gql`
    query OrgSettings($organisationId: String!) {
        organisation(id: $organisationId) {
            id
            name
            whitelistedEmails
            settings {
                sso {
                    slug
                    customDomain
                    enabled
                    ssoRequired
                    loginUrl
                    logoutUrl
                    defaultRole
                    metadataUrl
                    callbackUrl
                    publicKey
                }
                login {
                    twofactor
                    twofactorRoleIds
                    twofactorRoles {
                        name
                    }
                    whitelistedAutoApprove
                    whitelistedDefaultRoleId
                    whitelistedDefaultRole {
                        name
                    }
                }
            }
        }
    }
`;

export const ORGANISATION_SMALL = gql`
    query OrganisationSmall($id: String!) {
        organisation(id: $id) {
            id
            name
            logo
            address {
                line1
                line2
                zip
                city
                country
            }
            legal {
                branchId
            }
        }
    }
`;

export const ORGANISATIONS_MINIMAL = gql`
    query Organisations($first: Int, $skip: Int, $where: JSON, $sort: [String], $caseSensitive: Boolean) {
        organisations(first: $first, skip: $skip, where: $where, sort: $sort, caseSensitive: $caseSensitive) {
            id
            name
            logo
            type
            status
            legal {
                registrationId
            }
            settings {
                login {
                    twofactor
                }
            }
            products {
                slug
                enabled
            }
            childs {
                address {
                    city
                }
            }
        }
    }
`;

export const ORGANISATIONS_ANALYIS_PAGE = gql`
    query Organisations($first: Int, $skip: Int, $where: JSON, $sort: [String], $caseSensitive: Boolean) {
        organisations(first: $first, skip: $skip, where: $where, sort: $sort, caseSensitive: $caseSensitive) {
            id
            name
            type
            products {
                slug
                enabled
            }
        }
    }
`;

export const ORGANISATION_API_KEYS = gql`
    query OrganisationKeys($id: String!) {
        organisation(id: $id) {
            id
            name
            integration {
                keys {
                    id
                    name
                    key
                    creationDate
                }
            }
        }
    }
`;

export const GLOBAL_SEARCH = gql`
    query GlobalSearch($search: String!, $first: Int) {
        shared_globalSearch(search: $search, first: $first) {
            organisations {
                name
                logo
                id
            }
            projects {
                name
                clientProjectName
                contractorProjectName
                id
            }
            users {
                firstName
                lastName
                email
                avatar
                id
            }
            forms {
                id
                name
                project {
                    id
                    name
                }
            }
        }
    }
`;

export const SENT_RELEASENOTES = gql`
    query ReleaseNotes {
        releasenotes(where: { status: "sent" }, sort: "date__DESC") {
            id
            title
            description
            date
            version
            lines
            status
        }
    }
`;

export const ACCESS_USER_ACCOUNT = gql`
    query AccessUserAccount($id: String!) {
        user_accessAccount(id: $id) {
            jwt
            refreshToken
            user {
                id
                firstName
                lastName
                phone
                email
                changeEmail
                status
                avatar
                organisations {
                    organisationId
                    roleId
                    status
                }
            }
        }
    }
`;

// DataDynamiQ

export const ACCESS_REPORT = gql`
    query AccessReport($id: String!) {
        report_access(id: $id) {
            jwt
            report {
                id
                name
                roles {
                    role
                    permissions
                }
                users {
                    user {
                        id
                        firstName
                        lastName
                        avatar
                        organisations {
                            organisationId
                            roleId
                        }
                    }
                    permissions
                }
                configuration
                building
                syncedWithConfiguration, 
                isFormTemplateResultset
            }
        }
    }
`;

export const GET_REPORT = gql`
    query GetReport($id: String!) {
        report(id: $id) {
            syncedWithConfiguration, 
            isFormTemplateResultset
        }
    }
`;

export const REPORTS_MINIMAL = gql`
    query Reports($where: JSON, $first: Int, $skip: Int, $sort: [String], $caseSensitive: Boolean) {
        reports(where: $where, first: $first, skip: $skip, sort: $sort, caseSensitive: $caseSensitive) {
            id
            name 
            configuration
        }
    }
`;

export const REPORTS = gql`
    query Reports($where: JSON, $first: Int, $skip: Int, $sort: [String], $caseSensitive: Boolean) {
        reports(where: $where, first: $first, skip: $skip, sort: $sort, caseSensitive: $caseSensitive) {
            id
            name
            roles {
                role
                permissions
            }
            users {
                user {
                    id
                    firstName
                    lastName
                    avatar
                    organisations {
                        organisationId
                        roleId
                    }
                }
                permissions
            }
            owners {
                firstName
                lastName
            }
            configuration
            lastUpdated, 
            isFormTemplateResultset
        }
    }
`;

export const GET_REPORT_USERS = gql`
    query report($id: String!) {
        report(id: $id) {
            users {
                user {
                    id
                    firstName
                    lastName
                    avatar
                    organisations {
                        organisationId
                        roleId
                    }
                }
                permissions
            }
        }
    }
`;

export const GET_REPORT_RESULTSET = gql`
    query resultset($reportId: String!, $conditions: JSON!, $limit: Int!, $offset: Int!, $orderBy: [JSON]!, $options: JSON!) {
        resultset(reportId: $reportId, query: { conditions: $conditions, limit: $limit, offset: $offset, orderBy: $orderBy, options: $options })
    }
`;

export const GET_REPORT_RESULTSET_TOTAL_AMOUNT = gql`
    query resultsetTotalAmount($reportId: String!, $conditions: JSON!, $orderBy: [JSON]!, $options: JSON!) {
        resultsetTotalAmount(reportId: $reportId, query: { conditions: $conditions, orderBy: $orderBy, options: $options })
    }
`;

export const GET_REPORT_RESULTSET_FILTER_OPTIONS = gql`
    query resultsetFilterOptions($reportId: String!, $fields: [JSON]!) {
        resultsetFilterOptions(reportId: $reportId, fields: $fields)
    }
`;

export const REFERENCE_LETTER = gql`
    query ReferenceLetter($id: String!) {
        referenceLetter(id: $id) {
            id
            showAllProjectReferences
            exportAsLetter
            styleId
            addressReceiver {
                name
                line1
                line2
            }
            introText
            closingText
            order
            hiddenComponentIds
            step
        }
    }
`;

export const CONFIGURATION = gql`
    query Configuration($configurationId: String) {
        org_configuration(configurationId: $configurationId) {
            id
            name
            type
            color {
                name
                colorCode
            }
            address {
                name
                sender
                line1
                line2
            }
            logo
            text
            createdUser {
                id
                firstName
                lastName
            }
            creationDate
            lastEdited
        }
    }
`;

export const CONFIGURATIONS = gql`
    query Configurations {
        org_configurations {
            id
            name
            type
            color {
                name
                colorCode
            }
            address {
                name
                sender
                line1
                line2
            }
            logo
            text
            createdUser {
                id
                firstName
                lastName
            }
            creationDate
            lastEdited
        }
    }
`;

export const GET_PROJECT_DOCUMENTS = gql`
    query GetProjectDocuments($projectId: String!, $search: String, $documentId: String, $fields: [String]!, $projectJwt: String!) {
        documents_getProjectDocuments(projectId: $projectId, search: $search, documentId: $documentId, fields: $fields, projectJwt: $projectJwt)
    }
`;


export const GET_DOCUMENT_URL = gql`
    query GetDocumentUrl($fileName: String!) {
        document_getUrl(fileName: $fileName)
    }
`;


export const SEARCH_PROJECT_DOCUMENTS = gql`
    query SearchProjectDocuments($projectId: String!, $search: String, $documentId: String, $projectJwt: String!) {
        documents_searchProjectDocuments(projectId: $projectId, search: $search, documentId: $documentId, projectJwt: $projectJwt)
    }
`;
